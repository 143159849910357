import { Button, InputAdornment, MenuItem, TextField } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { Form, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import React from 'react';
import * as Yup from 'yup';
import { GET_FEES_QUERY } from '../resolvers/Queries';
import RupeeIcon from './RupeeIcon';

const months = [
  'None',
  'JAN',
  'FEB',
  'MAR',
  'APR',
  'MAY',
  'JUN',
  'JUL',
  'AUG',
  'SEP',
  'OCT',
  'NOV',
  'DEC',
];
const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
export default function UpdateFeeForm(props) {
  const { open, handleClose, data, updateFees, termID, feeCatID } = props;
  const { enqueueSnackbar } = useSnackbar();
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Update Fee
        </DialogTitle>
        <DialogContent>
          <Formik
            enableReinitialize
            initialValues={{
              name: data.name,
              amount: data.amount,
              month: data.due || 'None',
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().required('Name for Fee'),
              amount: Yup.number().required('Amount is required'),
              month: Yup.string(),
            })}
            onSubmit={async values => {
              try {
                await updateFees({
                  variables: {
                    feeID: data.id,
                    cols: {
                      name: values.name,
                      amount: values.amount,
                      month: values.month === 'None' ? null : values.month,
                    },
                  },
                  refetchQueries: [
                    {
                      query: GET_FEES_QUERY,
                      variables: {
                        feesStructureID: feeCatID,
                        termID,
                      },
                    },
                  ],

                  // update: (cache, { data: { update_fees_by_pk: fees } }) => {
                  //   console.log(cache);
                  //   cache.writeQuery({
                  //     query: GET_FEES_QUERY,
                  //     data: fees,
                  //     id: `fees:${fees.id}`,
                  //   });
                  // },
                });
                enqueueSnackbar(
                  `${values.name} has been updated succesfully!`,
                  {
                    variant: 'success',
                    anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                  }
                );
              } catch (e) {
                enqueueSnackbar(`Something Went Wrong. Heres the error ${e}`, {
                  variant: 'error',
                  anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                });
                console.log(e);
              }
              handleClose();
            }}
          >
            {p => {
              const {
                values,
                touched,
                errors,
                handleChange,
                handleBlur,
                setFieldValue,
              } = p;
              return (
                <Form>
                  <TextField
                    label="Fee Name"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={errors.name && touched.name && errors.name}
                    margin="normal"
                    fullWidth
                    required
                    variant="outlined"
                  />
                  <TextField
                    label="Amount"
                    name="amount"
                    value={values.amount}
                    onChange={e => {
                      if (e.target.value !== '') {
                        setFieldValue(
                          e.target.name,
                          parseInt(e.target.value),
                          true
                        );
                      } else {
                        setFieldValue(e.target.name, e.target.value, true);
                      }
                    }}
                    onBlur={handleBlur}
                    helperText={
                      errors.amount && touched.amount && errors.amount
                    }
                    margin="normal"
                    fullWidth
                    required
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <RupeeIcon color="primary" />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    select
                    name="month"
                    label="Select Due Date"
                    variant="outlined"
                    value={values.month}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                  >
                    {months.map(option => (
                      <MenuItem key={option} value={option}>
                        {`${option}`}
                      </MenuItem>
                    ))}
                  </TextField>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    startIcon={<CloudUploadIcon />}
                  >
                    Update Fee
                  </Button>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
}
