import gql from 'graphql-tag';

export const CREATE_NEW_SCHOOL = gql`
  mutation CREATE_NEW_SCHOOL($data: terms_insert_input!) {
    insert_terms_one(object: $data) {
      id
      name
      current
      school {
        id
        name
      }
    }
  }
`;
export const CREATE_USER_MUTATION = gql`
  mutation CREATE_USER_MUTATION($data: users_insert_input!) {
    insert_users_one(object: $data) {
      id
    }
  }
`;

export const CREATE_USERS_MUTATION = gql`
  mutation CREATE_USERS_MUTATION($data: [users_insert_input!]!) {
    insert_users(objects: $data) {
      returning {
        id
        email
      }
    }
  }
`;
export const CREATE_SECTIONS_MUTATION = gql`
  mutation CREATE_SECTIONS_MUTATION($data: [sections_insert_input!]!) {
    insert_sections(objects: $data) {
      returning {
        id
      }
    }
  }
`;
export const UPDATE_USER_MUTATION = gql`
  mutation UPDATE_USER_MUTATION($id: uuid!, $cols: users_set_input!) {
    update_users_by_pk(pk_columns: { id: $id }, _set: $cols) {
      name
    }
  }
`;
export const TOGGLE_USER_MUTATION = gql`
  mutation TOGGLE_USER_MUTATION($id: uuid!, $cols: users_set_input!) {
    update_users_by_pk(pk_columns: { id: $id }, _set: $cols) {
      name
    }
  }
`;
export const UPDATE_CLASSES_ADMINS_MUTATION = gql`
  mutation UPDATE_CLASSES_ADMINS_MUTATION(
    $id: uuid!
    $data: [classes_admins_insert_input!]!
  ) {
    delete_classes_admins(where: { adminID: { _eq: $id } }) {
      affected_rows
    }
    insert_classes_admins(objects: $data) {
      returning {
        adminID
      }
    }
  }
`;
export const DELETE_USER_MUTATION = gql`
  mutation DELETE_USER_MUTATION($where: users_bool_exp!) {
    delete_users(where: $where) {
      affected_rows
    }
  }
`;

export const CREATE_CLASS_MUTATION = gql`
  mutation CREATE_CLASS_MUTATION($data: classes_insert_input!) {
    insert_classes_one(object: $data) {
      class
      id
      smsSuffix
    }
  }
`;

export const UPDATE_CLASS_MUTATION = gql`
  mutation UPDATE_CLASS_MUTATION($data: classes_set_input!, $classID: bigint!) {
    update_classes_by_pk(pk_columns: { id: $classID }, _set: $data) {
      id
    }
  }
`;
export const UPDATE_CLASSES_MUTATION = gql`
  mutation UPDATE_CLASSES_MUTATION($ids: [bigint!]!, $suffix: String!) {
    update_classes(where: { id: { _in: $ids } }, _set: { smsSuffix: $suffix }) {
      affected_rows
    }
  }
`;

export const CREATE_SECTION_MUTATION = gql`
  mutation CREATE_SECTION_MUTATION($data: sections_insert_input!) {
    insert_sections_one(object: $data) {
      id
    }
  }
`;

export const UPDATE_SECTION_MUTATION = gql`
  mutation UPDATE_SECTION_MUTATION(
    $data: sections_set_input!
    $sectionID: bigint!
    $sectionTeachers: [section_teachers_insert_input!]!
  ) {
    update_sections_by_pk(pk_columns: { id: $sectionID }, _set: $data) {
      id
    }
    delete_section_teachers(where: { sectionID: { _eq: $sectionID } }) {
      affected_rows
    }
    insert_section_teachers(objects: $sectionTeachers) {
      affected_rows
    }
  }
`;

export const DELETE_SECTION_MUTATION = gql`
  mutation DELETE_SECTION_MUTATION($id: bigint!) {
    delete_sections_by_pk(id: $id) {
      id
    }
  }
`;

export const CREATE_STUDENT_MUTATION = gql`
  mutation CREATE_STUDENT_MUTATION($data: students_insert_input!) {
    insert_students_one(object: $data) {
      id
    }
  }
`;

export const UPDATE_STUDENT_MUTATION = gql`
  mutation UPDATE_STUDENT_MUTATION($data: students_set_input!, $id: bigint!) {
    update_students_by_pk(pk_columns: { id: $id }, _set: $data) {
      id
    }
  }
`;
export const ADD_STUDENTS_FEES_STRUCTURE = gql`
  mutation ADD_STUDENTS_FEES_STRUCTURE(
    $students_fees_structures: students_fees_structures_insert_input!
  ) {
    insert_students_fees_structures_one(object: $students_fees_structures) {
      studentID
    }
  }
`;
export const DEL_STUDENTS_FEES_STRUCTURE = gql`
  mutation DEL_STUDENTS_FEES_STRUCTURE($studentID: bigint!, $termID: bigint) {
    delete_students_fees_structures(
      where: {
        studentID: { _eq: $studentID }
        # fees_structure: { termID: { _eq: $termID } }
      }
    ) {
      affected_rows
    }
  }
`;
export const CREATE_STUDENTS_MUTATION = gql`
  mutation CREATE_STUDENTS_MUTATION($data: [students_insert_input!]!) {
    insert_students(objects: $data) {
      returning {
        id
      }
    }
  }
`;

export const CREATE_ATTS_MUTATION = gql`
  mutation CREATE_ATTS_MUTATION($data: [AttendanceCreateInput!]!) {
    createAttendances(data: $data) {
      id
    }
  }
`;

export const UPDATE_SCHOOL_MUTATION = gql`
  mutation UPDATE_SCHOOL_MUTATION($id: uuid!, $cols: schools_set_input!) {
    update_schools_by_pk(pk_columns: { id: $id }, _set: $cols) {
      id
    }
  }
`;

export const CREATE_TERM_MUTATION = gql`
  mutation CREATE_TERM_MUTATION($data: terms_insert_input!) {
    insert_terms_one(object: $data) {
      id
    }
  }
`;
export const UPDATE_TERM_MUTATION = gql`
  mutation UPDATE_TERM_MUTATION($id: bigint!, $schoolID: uuid!) {
    update_terms(
      where: {
        _and: [{ schoolID: { _eq: $schoolID } }, { _not: { id: { _eq: $id } } }]
      }
      _set: { current: false }
    ) {
      affected_rows
    }
    update_terms_by_pk(pk_columns: { id: $id }, _set: { current: true }) {
      id
    }
  }
`;
export const PROMOTE_CLASSES_MUTATION = gql`
  mutation PROMOTE_CLASSES_MUTATION($schoolID: uuid!, $termID: bigint!) {
    update_classes(
      where: { schoolID: { _eq: $schoolID } }
      _set: { termID: $termID }
    ) {
      affected_rows
    }
  }
`;
export const PROMOTE_SECTIONS_MUTATIONS = gql`
  mutation PROMOTE_SECTIONS_MUTATIONS(
    $id: bigint!
    $termID: bigint!
    $data: [section_teachers_insert_input!]!
  ) {
    update_sections_by_pk(pk_columns: { id: $id }, _set: { termID: $termID }) {
      id
    }
    delete_section_teachers(where: { sectionID: { _eq: $id } }) {
      affected_rows
    }
    insert_section_teachers(objects: $data) {
      affected_rows
    }
  }
`;
export const PROMOTE_STUDENTS_MUTATIONS = gql`
  mutation PROMOTE_STUDENTS_MUTATIONS(
    $schoolID: uuid!
    $prevTerm: bigint!
    $oldSecID: bigint!
    $classID: bigint!
    $sectionID: bigint!
    $termID: bigint!
  ) {
    update_students(
      where: {
        schoolID: { _eq: $schoolID }
        sectionID: { _eq: $oldSecID }
        termID: { _eq: $prevTerm }
      }
      _set: { termID: $termID, sectionID: $sectionID, classID: $classID }
    ) {
      affected_rows
    }
  }
`;

export const ADD_FEES_ONE_MUTATION = gql`
  mutation ADD_FEES_ONE_MUTATION($data: fees_insert_input!) {
    insert_fees_one(object: $data) {
      id
    }
  }
`;
export const ADD_FEES_MUTATION = gql`
  mutation ADD_FEES_MUTATION($data: [fees_insert_input!]!) {
    insert_fees(objects: $data) {
      returning {
        id
      }
    }
  }
`;

export const UPDATE_FEES_MUTATION = gql`
  mutation UPDATE_FEES_MUTATION($feeID: bigint!, $cols: fees_set_input!) {
    update_fees_by_pk(pk_columns: { id: $feeID }, _set: $cols) {
      id
      sr
      name
      amount
      slug
      month
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_FEE_MUTATION = gql`
  mutation DELETE_FEE_MUTATION($id: bigint!) {
    delete_fees_by_pk(id: $id) {
      id
    }
  }
`;

export const FEES_COLLECTED_MUTATION = gql`
  mutation FEES_COLLECTED_MUTATION($data: [fees_collected_insert_input!]!) {
    insert_fees_collected(objects: $data) {
      returning {
        feeID
      }
    }
  }
`;
export const UPDATE_FEES_COLLECTED = gql`
  mutation UPDATE_FEES_COLLECTED(
    $collectedIDs: bigint!
    $cols: fees_collected_set_input!
  ) {
    update_fees_collected(where: { id: { _eq: $collectedIDs } }, _set: $cols) {
      affected_rows
    }
  }
`;

export const CREATE_ORDER_MUTATION = gql`
  mutation CREATE_ORDER_MUTATION($data: OrderInput!) {
    createOrder(data: $data) {
      txnid
      hash
      key
    }
  }
`;
export const VERIFY_ONLINEPAYMENT_MUTATION = gql`
  mutation VERIFY_ONLINEPAYMENT_MUTATION(
    $razorpay_payment_id: String!
    $razorpay_order_id: String!
    $razorpay_signature: String!
    $schoolID: uuid!
    $studentID: bigint!
    $termID: bigint!
    $feeIDs: String!
  ) {
    updateOnlinepayment(
      data: {
        razorpay_payment_id: $razorpay_payment_id
        razorpay_order_id: $razorpay_order_id
        razorpay_signature: $razorpay_signature
        schoolID: $schoolID
        studentID: $studentID
        termID: $termID
        feeIDs: $feeIDs
      }
    ) {
      status
    }
  }
`;
export const ADD_FEE_STRUCTURE_MUTATION = gql`
  mutation ADD_FEE_STRUCTURE_MUTATION($data: fees_structure_insert_input!) {
    insert_fees_structure_one(object: $data) {
      id
    }
  }
`;
export const BULK_ADD_FEE_STRUCTURE_MUTATION = gql`
  mutation BULK_ADD_FEE_STRUCTURE_MUTATION(
    $data: [fees_structure_insert_input!]!
  ) {
    insert_fees_structure(objects: $data) {
      affected_rows
    }
  }
`;
export const DELETE_FEE_STRUCTURE_MUTATION = gql`
  mutation DELETE_FEE_STRUCTURE_MUTATION($id: bigint!) {
    delete_fees_structure_by_pk(id: $id) {
      id
    }
  }
`;
export const UPDATE_USER_PASS = gql`
  mutation UPDATE_USER_PASS($id: String!, $password: String!) {
    update_user_password(id: $id, password: $password)
  }
`;

export const DELETE_FEES_COLLECTED_MUTATION = gql`
  mutation DELETE_FEES_COLLECTED_MUTATION($id: bigint!) {
    update_payments_by_pk(pk_columns: { id: $id }, _set: { deleted: true }) {
      id
    }
    delete_fees_collected(where: { paymentID: { _eq: $id } }) {
      affected_rows
    }
  }
`;
export const DELETE_DISCOUNTS_MUTATION = gql`
  mutation DELETE_DISCOUNTS_MUTATION(
    $schoolID: uuid!
    $studentID: bigint!
    $ids: [bigint!]
  ) {
    delete_fees_collected(
      where: {
        schoolID: { _eq: $schoolID }
        studentID: { _eq: $studentID }
        id: { _in: $ids }
      }
    ) {
      affected_rows
    }
  }
`;
export const ADD_ACCOUNT_MUTATION = gql`
  mutation ADD_ACCOUNT_MUTATION($t: String!) {
    addAccount(t: $t) {
      id
    }
  }
`;
export const UPDATE_ACCOUNT_MUTATION = gql`
  mutation UPDATE_ACCOUNT_MUTATION($t: String!, $id: Int!) {
    updateAccount(t: $t, id: $id) {
      id
    }
  }
`;
export const ADD_PAYMENT_MUTATION = gql`
  mutation ADD_PAYMENT_MUTATION($data: payments_insert_input!) {
    insert_payments_one(object: $data) {
      id
      amount
      fees_collecteds {
        feeID
      }
    }
  }
`;
export const ADD_EXP_CAT_MUTATION = gql`
  mutation ADD_EXP_CAT_MUTATION($data: expense_cats_insert_input!) {
    insert_expense_cats_one(object: $data) {
      category
      schoolID
    }
  }
`;
export const DEL_EXP_CAT_MUTATION = gql`
  mutation DEL_EXP_CAT_MUTATION($id: bigint!) {
    delete_expense_cats(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
export const ADD_EXPENSE_MUTATION = gql`
  mutation ADD_EXPENSE_MUTATION($data: expenses_insert_input!) {
    insert_expenses_one(object: $data) {
      id
    }
  }
`;
export const DEL_EXPENSE_MUTATION = gql`
  mutation DEL_EXPENSE_MUTATION($id: bigint!) {
    delete_expenses_by_pk(id: $id) {
      id
    }
  }
`;

export const CREATE_SUBJECT_MUTATION = gql`
  mutation CREATE_SUBJECT_MUTATION($data: subjects_insert_input!) {
    insert_subjects_one(object: $data) {
      id
    }
  }
`;
export const UPDATE_SUBJECT_MUTATION = gql`
  mutation UPDATE_SUBJECT_MUTATION(
    $data: subjects_set_input!
    $subjectID: bigint!
    $subjectTeachers: [subject_teachers_insert_input!]!
  ) {
    update_subjects_by_pk(pk_columns: { id: $subjectID }, _set: $data) {
      id
    }
    delete_subject_teachers(where: { subjectID: { _eq: $subjectID } }) {
      affected_rows
    }
    insert_subject_teachers(objects: $subjectTeachers) {
      affected_rows
    }
  }
`;
export const CREATE_BOOK_MUTATION = gql`
  mutation CREATE_BOOK_MUTATION($data: books_insert_input!) {
    insert_books_one(object: $data) {
      id
    }
  }
`;
export const UPDATE_BOOK_MUTATION = gql`
  mutation UPDATE_BOOK_MUTATION(
    $data: books_set_input!
    $bookID: bigint!
    $booksTeachers: [books_teachers_insert_input!]!
  ) {
    update_books_by_pk(pk_columns: { id: $bookID }, _set: $data) {
      id
    }
    delete_books_teachers(where: { bookID: { _eq: $bookID } }) {
      affected_rows
    }
    insert_books_teachers(objects: $booksTeachers) {
      affected_rows
    }
  }
`;
export const DELETE_SUBJECT_MUTATION = gql`
  mutation DELETE_SUBJECT_MUTATION($subjectID: bigint!) {
    delete_subjects_by_pk(id: $subjectID) {
      id
    }
  }
`;
export const DELETE_BOOK_MUTATION = gql`
  mutation DELETE_BOOK_MUTATION($bookID: bigint!) {
    delete_books_by_pk(id: $bookID) {
      id
    }
  }
`;
export const FINISH_LECTURE_MUTATION = gql`
  mutation FINISH_LECTURE_MUTATION($attID: String!, $timestamp: String!) {
    endLecture(data: { attID: $attID, timestamp: $timestamp }) {
      id
    }
  }
`;
export const BOOKS_PROGRESS_MUTATION = gql`
  mutation BOOKS_PROGRESS_MUTATION($data: [books_progress_insert_input!]!) {
    insert_books_progress(objects: $data) {
      returning {
        id
      }
    }
  }
`;

export const ADD_TOKEN_MUTATION = gql`
  mutation ADD_TOKEN_MUTATION(
    $data: [token_insert_input!]!
    $conflict_rule: token_on_conflict
  ) {
    insert_token(objects: $data, on_conflict: $conflict_rule) {
      affected_rows
    }
  }
`;
export const MARK_ATT_MUTATION = gql`
  mutation MARK_ATT_MUTATION(
    $sAttID: bigint!
    $value: bpchar
    $notification: jsonb
    $active: Boolean
  ) {
    update_studentAttendances_by_pk(
      pk_columns: { id: $sAttID }
      _set: { value: $value, active: $active }
      _append: { notifications: $notification }
    ) {
      id
      active
      notifications
    }
  }
`;
export const MARK_ACTIVE_MUTATION = gql`
  mutation MARK_ACTIVE_MUTATION(
    $sAttID: bigint!
    $notification: jsonb
    $active: Boolean
  ) {
    update_studentAttendances_by_pk(
      pk_columns: { id: $sAttID }
      _set: { active: $active }
      _append: { notifications: $notification }
    ) {
      id
    }
  }
`;
export const ADD_BUS_MUTATION = gql`
  mutation ADD_BUS_MUTATION($data: buses_insert_input!) {
    insert_buses_one(object: $data) {
      id
    }
  }
`;
export const UPDATE_BUS_MUTATION = gql`
  mutation UPDATE_BUS_MUTATION($data: buses_set_input!, $id: Int!) {
    update_buses_by_pk(pk_columns: { id: $id }, _set: $data) {
      id
    }
  }
`;

export const DELETE_STUDENTS_MUTATION = gql`
  mutation DELETE_STUDENT_MUTATION($id: bigint!) {
    delete_students_by_pk(id: $id) {
      id
    }
  }
`;
export const SEND_WHATSAPP_MSG_MUTATION = gql`
  mutation SEND_WHATSAPP_MSG_MUTATION($data: WhatsappInput!) {
    sendWhatsapp(data: $data)
  }
`;
