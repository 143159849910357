import { useMutation } from '@apollo/client';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { Field, Form, Formik } from 'formik';
import moment from 'moment';
import React from 'react';
import * as Yup from 'yup';
import {
  CREATE_NEW_SCHOOL,
  UPDATE_SCHOOL_MUTATION,
} from '../resolvers/Mutations';
import { GET_SCHOOLS_QUERY, GET_SCHOOL_QUERY } from '../resolvers/Queries';
import CheckboxInputs from './CheckboxInput';
import Loader from './Loader';
import RadioInputs from './RadioInputs';
import SelectField from './SelectField';
import MyTimePicker from './TimePicker';
import UserContext from './UserContext';

const dates = [];
for (let i = 1; i <= 30; i++) {
  dates.push({ value: i, label: i });
}
const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  radio: {
    width: '100%',
    marginBottom: 10,
  },
  options: {
    width: '100%',
    marginBottom: 10,
  },
  break: {
    flexBasis: '100%',
    height: 0,
  },
}));
const days = [
  {
    value: '0',
    label: 'Sunday',
  },
  {
    value: '1',
    label: 'Monday',
  },
  {
    value: '2',
    label: 'Tuesday',
  },
  {
    value: '3',
    label: 'Wednesday',
  },
  {
    value: '4',
    label: 'Thursday',
  },
  {
    value: '5',
    label: 'Friday',
  },
  {
    value: '6',
    label: 'Saturday',
  },
];
export default function AddSchoolForm(props) {
  const classes = useStyles();
  const { user } = React.useContext(UserContext);
  const { school, update } = props;
  const [createSchool, createSchoolData] = useMutation(CREATE_NEW_SCHOOL);
  const [updateSchool, updateSchoolData] = useMutation(UPDATE_SCHOOL_MUTATION);
  const [loading, setLoading] = React.useState(false);
  const submit = async values => {
    setLoading(true);
    const data = {};
    if (user.permissions === 'SUPERADMIN') {
      data.senderID = values.senderID;
      data.weeklySms = values.weeklySms;
      data.onlyAbsent = values.onlyAbsent;
      data.att_type = values.att_type;
      data.feesModule = values.feesModule;
      data.onlineFees = values.onlineFees;
      data.feesDate = values.feesDate;
      data.feePercent = values.feePercent;
      data.qrcode = values.qrcode;
      data.chat = values.chat;
      data.attNotification = values.attNotification;
      data.liveLecture = values.liveLecture;
      data.buses = values.buses;
      data.rfid = values.rfid;
      data.discipline = values.discipline;
      if (
        values.qrcode ||
        values.liveLecture ||
        values.chat ||
        values.rfid ||
        values.discipline ||
        values.attNotification
      )
        data.studentEmail = values.studentEmail;
      else data.studentEmail = '';
    }

    if (data.att_type === 'SUBJECTWISE') {
      data.subjectwiseSmsTime = values.subjectwiseSmsTime;
      data.weeklySmsTime = values.weeklySmsTime;
      data.displayMins = values.displayMins;
      if (data.weeklySms) {
        data.weeklySmsTime = values.weeklySmsTime;
        data.weeklySmsDay = values.weeklySmsDay;
      }
    } else {
      data.name = values.name;
      data.description = values.description;
      data.adminPhone = values.adminPhone;
      data.defaultAbsent = values.defaultAbsent;
      data.showRegisterNo = values.showRegisterNo;
      data.manySchoolNames = values.manySchoolNames;
    }
    // CREATE NEW SCHOOL
    if (!update) {
      await createSchool({
        variables: {
          data: {
            name: values.term,
            current: true,
            school: {
              data,
            },
          },
        },
        refetchQueries: [
          { query: GET_SCHOOLS_QUERY, fetchPolicy: 'network-only' },
        ],
      });
    } else {
      console.log(data);
      await updateSchool({
        variables: {
          id: school.id,
          cols: data,
        },
        refetchQueries: [
          {
            query: GET_SCHOOL_QUERY,
            variables: { id: school.id },
            fetchPolicy: 'network-only',
          },
        ],
      });
    }
    setLoading(false);
  };

  if (createSchoolData.loading || updateSchoolData.loading || loading)
    return <Loader />;
  return (
    <Formik
      initialValues={{
        name: update ? school.name : '',
        description: update ? school.description || '' : '',
        adminPhone: update ? school.adminPhone : '',
        term: update ? school.terms[0].name : '',
        senderID: update ? school.senderID : 'SSTRAK',
        displayMins:
          update && school.displayMins ? school.displayMins.join(',') : '',
        defaultMins: update ? school.defaultMins : '',
        att_type: update ? school.att_type : 'Daily',
        defaultAbsent: update ? school.defaultAbsent : false,
        onlyAbsent: update ? school.onlyAbsent : false,
        showRegisterNo: update ? school.showRegisterNo : false,
        smsDisabled: update ? school.smsDisabled : false,
        weeklySms: update ? school.weeklySms : false,
        weeklySmsDay: update ? school.weeklySmsDay : '1',
        weeklySmsTime:
          update && school.weeklySmsTime ? school.weeklySmsTime : moment(),
        subjectwiseSmsTime:
          update && school.subjectwiseSmsTime
            ? school.subjectwiseSmsTime
            : moment(),
        breakdownLectures: update ? school.breakdownLectures : false,
        timetableSummary: update ? school.timetableSummary : false,
        manySchoolNames: update ? school.manySchoolNames : false,
        feesModule: update ? school.feesModule : false,
        onlineFees: update && school.onlineFees ? school.onlineFees : false,
        feesDate: update ? school.feesDate : 5,
        feePercent: update ? school.feePercent : 0.001,
        qrcode: update ? school.qrcode : false,
        liveLecture: update ? school.liveLecture : false,
        chat: update ? school.chat : false,
        attNotification: update ? school.attNotification : false,
        studentEmail: update ? school.studentEmail : '.@sstracker.in',
        buses: update ? school.buses : false,
        rfid: update ? school.rfid : false,
        discipline: update ? school.discipline : false,
      }}
      enableReinitialize
      onSubmit={async values => {
        await submit(values);

        // TODO Change Panel After Adding new School
        // props.changePanel('', 0);
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required('Required'),
        description: Yup.string(),
        adminPhone: Yup.string().required('Required'),
        term: Yup.string().required('Required'),
        senderID: Yup.string().required('Required'),
        displayMins: Yup.string(),
        defaultMins: Yup.string().nullable(),
        att_type: Yup.string().required('Required'),
        defaultAbsent: Yup.boolean(),
        onlyAbsent: Yup.boolean(),
        showRegisterNo: Yup.boolean(),
        smsDisabled: Yup.boolean(),
        weeklySms: Yup.boolean().nullable(),
        weeklySmsDay: Yup.string().nullable(),
        studentEmail: Yup.string().nullable(),
        weeklySmsTime: Yup.date().nullable(),
        subjectwiseSmsTime: Yup.date(),
        breakdownLectures: Yup.boolean().nullable(),
        timetableSummary: Yup.boolean().nullable(),
        manySchoolNames: Yup.boolean(),
        feesModule: Yup.boolean(),
        onlineFees: Yup.boolean(),
        qrcode: Yup.boolean(),
        liveLecture: Yup.boolean(),
        chat: Yup.boolean(),
        attNotification: Yup.boolean(),
        feesDate: Yup.number(),
        feePercent: Yup.number(),
        buses: Yup.boolean(),
      })}
    >
      {p => {
        const { values, touched, errors, handleChange, handleBlur } = p;
        return (
          <Form className={classes.container}>
            <TextField
              label="School Name"
              name="name"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={errors.name && touched.name && errors.name}
              margin="normal"
              fullWidth
              required
              variant="outlined"
            />
            <TextField
              label="Description"
              name="description"
              value={values.description}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                errors.description && touched.description && errors.description
              }
              margin="normal"
              fullWidth
              variant="outlined"
            />
            <TextField
              label="Admin Phone"
              name="adminPhone"
              value={values.adminPhone}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                errors.adminPhone && touched.adminPhone && errors.adminPhone
              }
              margin="normal"
              fullWidth
              required
              variant="outlined"
            />
            <TextField
              label="Term Name"
              name="term"
              value={values.term}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={errors.term && touched.term && errors.term}
              margin="normal"
              fullWidth
              required
              variant="outlined"
              disabled={update}
            />
            <TextField
              label="SenderID"
              name="senderID"
              value={values.senderID}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                errors.senderID && touched.senderID && errors.senderID
              }
              margin="normal"
              fullWidth
              required
              variant="outlined"
              disabled={user.permissions !== 'SUPERADMIN'}
            />

            <Field
              name="feesModule"
              label="Enable Fees Module"
              component={CheckboxInputs}
              checked={values.feesModule}
              disabled={user.permissions !== 'SUPERADMIN'}
            />
            {values.feesModule && (
              <>
                <div className={classes.break} />
                <Field
                  name="onlineFees"
                  label="Enable Online Fees"
                  component={CheckboxInputs}
                  checked={values.onlineFees}
                  disabled={user.permissions !== 'SUPERADMIN'}
                />
              </>
            )}

            <div className={classes.break} />
            {values.feesModule && (
              <>
                <Field
                  name="feesDate"
                  component={SelectField}
                  options={dates}
                  label="Fee Reminder Date"
                  zIndex={10}
                />
                <div className={classes.break} />
                {user.permissions === 'SUPERADMIN' && (
                  <TextField
                    label="Fee Percent"
                    name="feePercent"
                    value={values.feePercent}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={
                      errors.feePercent &&
                      touched.feePercent &&
                      errors.feePercent
                    }
                    margin="normal"
                    fullWidth
                    required
                    variant="outlined"
                    disabled={user.permissions !== 'SUPERADMIN'}
                  />
                )}
                <div className={classes.break} />
              </>
            )}
            <Field
              name="defaultAbsent"
              label="Mark Students As Default Absent"
              component={CheckboxInputs}
              checked={values.defaultAbsent}
            />
            <div className={classes.break} />
            <Field
              name="onlyAbsent"
              label="Send Only Absent SMS"
              checked={values.onlyAbsent}
              component={CheckboxInputs}
              disabled={user.permissions !== 'SUPERADMIN'}
            />
            <div className={classes.break} />
            <Field
              name="showRegisterNo"
              label="Show Register Number"
              checked={values.showRegisterNo}
              component={CheckboxInputs}
            />
            <div className={classes.break} />
            <Field
              name="smsDisabled"
              label="Disable All SMS"
              checked={values.smsDisabled}
              component={CheckboxInputs}
              disabled={user.permissions !== 'SUPERADMIN'}
            />
            <div className={classes.break} />
            <Field
              name="qrcode"
              label="Enable QRCode Attendance"
              checked={values.qrcode}
              component={CheckboxInputs}
              disabled={user.permissions !== 'SUPERADMIN'}
            />
            <div className={classes.break} />
            <Field
              name="chat"
              label="Enable Chat"
              checked={values.chat}
              component={CheckboxInputs}
              disabled={user.permissions !== 'SUPERADMIN'}
            />
            <div className={classes.break} />
            <Field
              name="attNotification"
              label="Enable Attendance Notification (No Att SMS)"
              checked={values.attNotification}
              component={CheckboxInputs}
              disabled={user.permissions !== 'SUPERADMIN'}
            />
            <div className={classes.break} />
            <Field
              name="rfid"
              label="Enable RFID machine attendance"
              checked={values.rfid}
              component={CheckboxInputs}
              disabled={user.permissions !== 'SUPERADMIN'}
            />
            <div className={classes.break} />
            <Field
              name="discipline"
              label="Enable Discipline Tracking"
              checked={values.discipline}
              component={CheckboxInputs}
              disabled={user.permissions !== 'SUPERADMIN'}
            />
            <div className={classes.break} />
            <Field
              name="buses"
              label="Enable Buses Module"
              checked={values.buses}
              component={CheckboxInputs}
              disabled={user.permissions !== 'SUPERADMIN'}
            />
            <div className={classes.break} />
            <Field
              name="liveLecture"
              label="Enable Live Lectures"
              checked={values.liveLecture}
              component={CheckboxInputs}
              disabled={user.permissions !== 'SUPERADMIN'}
            />
            <div className={classes.break} />
            {(values.qrcode ||
              values.liveLecture ||
              values.chat ||
              values.attNotification ||
              values.rfid ||
              values.discipline) && (
                <div>
                  <TextField
                    label="Student Email Format"
                    name="studentEmail"
                    value={values.studentEmail}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={
                      errors.studentEmail &&
                      touched.studentEmail &&
                      errors.studentEmail
                    }
                    margin="normal"
                    fullWidth
                    required
                    variant="outlined"
                    disabled={user.permissions !== 'SUPERADMIN'}
                  />
                </div>
              )}
            <div className={classes.break} />

            <Field
              name="att_type"
              options={['DAILY', 'DUAL', 'SUBJECTWISE']}
              component={RadioInputs}
              disabled={update}
            />
            <div className={classes.break} />
            {values.attType === 'SUBJECTWISE' && (
              <div className={classes.options}>
                {!values.weeklySms && (
                  <div>
                    <Field
                      name="subjectwiseSmsTime"
                      label="Subjectwise Att Sms Time"
                      component={MyTimePicker}
                    />
                    <div className={classes.break} />
                  </div>
                )}

                <TextField
                  label="Minutes To Display(Comma Separated.e.g.60,120..)"
                  name="displayMins"
                  value={values.displayMins}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={
                    errors.displayMins &&
                    touched.displayMins &&
                    errors.displayMins
                  }
                  margin="normal"
                  fullWidth
                  required
                  variant="outlined"
                />
                {values.displayMins && (
                  <div>
                    <TextField
                      select
                      name="defaultMins"
                      label="Select Default Lecture Minutes"
                      variant="outlined"
                      value={values.defaultMins}
                      onChange={handleChange}
                      fullWidth
                      margin="normal"
                    >
                      {values.displayMins.split(',').map(option => (
                        <MenuItem key={option} value={option}>
                          {`${option} Mins`}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                )}
                <Field
                  name="breakdownLectures"
                  label="Breakdown Long Lecture To Multiple Lectures"
                  checked={values.breakdownLectures}
                  component={CheckboxInputs}
                />
                <div className={classes.break} />
                <Field
                  name="timetableSummary"
                  label="Enable Timetable Summary"
                  checked={values.timetableSummary}
                  component={CheckboxInputs}
                />
                <div className={classes.break} />
                <Field
                  name="weeklySms"
                  label="Send Weekly Sms"
                  checked={values.weeklySms}
                  component={CheckboxInputs}
                />
                <div className={classes.break} />
                {values.weeklySms && (
                  <div>
                    <TextField
                      select
                      name="weeklySmsDay"
                      label="Which Day To Send Weekly SMS"
                      variant="outlined"
                      value={values.weeklySmsDay}
                      onChange={handleChange}
                      fullWidth
                      margin="normal"
                    >
                      {days.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    <Field
                      name="weeklySmsTime"
                      label="Weekly SMS Time"
                      component={MyTimePicker}
                    />
                    <div className={classes.break} />
                  </div>
                )}
              </div>
            )}
            {update && (
              <>
                <Field
                  name="manySchoolNames"
                  label="Enable Multiple Names For School In Sms"
                  checked={values.manySchoolNames}
                  component={CheckboxInputs}
                />
                <div className={classes.break} />
              </>
            )}
            <Button
              type="submit"
              color="primary"
              variant="contained"
              startIcon={<CloudUploadIcon />}
            >
              {update ? 'Update School' : 'Save School'}
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
}
