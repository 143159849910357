/* eslint-disable no-nested-ternary */
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { Button, Container, Paper, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { FieldArray, Formik } from 'formik';
import * as moment from 'moment';
import React from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import Copyright from '../components/Copyright';
import Loader from '../components/Loader';
import SchoolContext from '../components/SchoolContext';
import UserContext from '../components/UserContext';
import {
  BOOKS_PROGRESS_MUTATION,
  FINISH_LECTURE_MUTATION,
} from '../resolvers/Mutations';
import {
  GET_ATT_LIVE_QUERY,
  GET_BOOKS_AND_PROGRESS,
  GET_LIVE_STUDENTS_ATTS,
  START_LIVE_MERGED_QUERY,
} from '../resolvers/Queries';

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    border: '1px solid #E7E7E7',
    backgroundColor: theme.palette.background,
    height: '100vh',
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  title: {
    backgroundColor: '#0EABBC',
    fontFamily: 'Montserrat',
    color: '#FFF',
    textAlign: 'center',
    height: 70,
  },
}));
export default function TeacherLive(props) {
  const history = useHistory();
  if (typeof history.location.state === 'undefined')
    return <Redirect to="/teacher" />;
  return <TeacherLiveFunction props={props} />;
}
function TeacherLiveFunction() {
  const classes = useStyles();
  const { user } = React.useContext(UserContext);
  const { school } = React.useContext(SchoolContext);
  const history = useHistory();
  const [live, setLive] = React.useState(true);

  const {
    items = false,
    activeTeacher = user,
    startTime: st = '',
    attid = false,
    attIDandBookID = false,
    names = false,
    repeat = false,
  } = history.location.state;
  const startTime = moment(st);
  const [attID, setAttID] = React.useState(attid ? attid.split(':') : []);
  const [exit, setExit] = React.useState(false);
  const [attIDbookID, setAttIDbookID] = React.useState(
    attIDandBookID
      ? attIDandBookID.split('|').reduce((res, item) => {
        const ts = item.split(':');
        return { ...res, ...{ [ts[0]]: ts[1] } };
      }, {})
      : {}
  );
  const [finishLecture, finishLectureData] = useMutation(
    FINISH_LECTURE_MUTATION
  );
  const [saveBooksProgress, saveBooksProgressData] = useMutation(
    BOOKS_PROGRESS_MUTATION
  );
  const [bookIDs, setBookIDs] = React.useState([]);
  const [bookProgress, setBookProgress] = React.useState(false);
  const [booksData, setBooksData] = React.useState({});
  const [timer, setTimer] = React.useState(moment().diff(startTime, 'seconds'));
  const client = useApolloClient();
  const stopInterval = setInterval(() => {
    setTimer(moment().diff(startTime, 'seconds'));
  }, 1000);
  React.useEffect(() => {
    const setup = async () => {
      try {
        let checkLiveData;
        const bookIDsCollected = [];
        let attIsLive;
        if (repeat && attID.length) {
          checkLiveData = await client.query({
            query: GET_ATT_LIVE_QUERY,
            variables: {
              attID,
            },
            fetchPolicy: 'network-only',
          });
          if (checkLiveData.data.attendances.length) {
            attIsLive = checkLiveData.data.attendances.filter(a => a.live)
              .length;
          }
          setLive(!!attIsLive);
          checkLiveData.data.attendances.map(a => {
            if (a.bookID) bookIDsCollected.push(a.bookID);
            return a;
          });
          setBookIDs(bookIDsCollected);
        }
        if (
          (items &&
            items.length &&
            items.filter(i => i.__typename === 'books').length) ||
          (checkLiveData && checkLiveData.data && !!bookIDsCollected)
        ) {
          const booksDataArr = [];
          if (!bookIDsCollected.length) {
            items
              .filter(i => i.__typename === 'books')
              .map(i => {
                bookIDsCollected.push(i.id);
                return i;
              });
          }
          const bookProgressData = await client.query({
            query: GET_BOOKS_AND_PROGRESS,
            variables: {
              bookIDs: bookIDsCollected,
              termID: school.termID,
            },
            fetchPolicy: 'network-only',
          });
          bookProgressData.data.books.map(book => {
            if (book.books_progresses.length) {
              book.books_progresses.map(b => {
                booksDataArr.push({
                  to: b.to,
                  id: b.id,
                  book: {
                    id: book.id,
                    name: book.name,
                  },
                });
                return b;
              });
            } else {
              booksDataArr.push({
                to: 0,
                book: {
                  id: book.id,
                  name: book.name,
                },
              });
            }
            return book;
          });
          setBookIDs(bookIDsCollected);
          setBooksData(booksDataArr);
        }
      } catch (e) {
        console.log('HERE', e);
      }
    };
    setup();
    return () => setup;
  }, [
    items,
    attID,
    attIDbookID,
    repeat,
    bookProgress,
    bookIDs,
    startTime,
    client,
    school.termID,
  ]);
  const attTime = moment()
    .utc()
    .date(startTime.date())
    .month(startTime.month())
    .year(startTime.year())
    .startOf('day')
    .toISOString(true);
  const attLiveVars = {
    attTime,
    termID: school.termID,
    schoolID: school.id,
    teacherID: activeTeacher.id,
    attsData: [],
  };
  if (!attID.length) {
    items.map(i => {
      const section = i && i.__typename === 'sections' ? i : i.section;
      const tmp = {
        classID: section.class.id,
        sectionID: section.id,
        subjectID:
          i.__typename === 'subjects'
            ? i.id
            : i.__typename === 'books'
              ? i.sub.id
              : 0,
        bookID: i.__typename === 'books' ? i.id : 0,
        multiselect: items.length > 1,
      };
      attLiveVars.attsData.push(tmp);
      return i;
    });
  }
  const startLiveData = useQuery(
    START_LIVE_MERGED_QUERY,
    !attID.length
      ? {
        variables: { data: attLiveVars },
        onCompleted: data => {
          setAttID(data.startLiveMerged.map(att => att.id));
          const d = {};
          data.startLiveMerged.map(att => (d[att.bookID] = att.id));
          setAttIDbookID(d);
          const localData = {
            attIDandBookID: Object.keys(d)
              .map(bookID => `${bookID}:${d[bookID]}`)
              .join('|'),
            attids: data.startLiveMerged.map(att => att.id).join(':'),
            startTime: startTime.toISOString(true),
            multiple: items.length > 1,
            names: items.map(i => ({ title: i.title, subtitle: i.subtitle })),
          };
          localStorage.setItem('live', JSON.stringify(localData));
        },
        fetchPolicy: 'network-only',
      }
      : { skip: true }
  );
  const studentsAttLive = useQuery(GET_LIVE_STUDENTS_ATTS, {
    skip: !attID.length,
    variables: {
      attID,
    },
    pollInterval: 2000,
    onCompleted: () => {
      if (!repeat) {
        if (user.zoomLink) window.open(user.zoomLink, '_blank');
      }
    },
  });
  const secondsToHms = d => {
    d = Number(d);
    const h = Math.floor(d / 3600);
    const m = Math.floor((d % 3600) / 60);
    const s = Math.floor((d % 3600) % 60);

    const hDisplay = h > 0 ? (h < 10 ? `0${h}` : h) : '00';
    const mDisplay = m > 0 ? (m < 10 ? `0${m}` : m) : '00';
    const sDisplay = s > 0 ? (s < 10 ? `0${s}` : s) : '00';
    return `${hDisplay}:${mDisplay}:${sDisplay}`;
  };
  if (
    (startLiveData && startLiveData.loading) ||
    studentsAttLive.loading ||
    finishLectureData.loading ||
    saveBooksProgressData.loading
  )
    return <Loader />;
  const sectionsList = [];
  const active = [];
  const absent = [];
  const notActive = [];
  if (studentsAttLive && studentsAttLive.data) {
    studentsAttLive.data.studentAttendances.map(s => {
      if (s.value === 'A') {
        const tmp = {
          ...s.student,
          status: 'absent',
        };
        absent.push(tmp);
      } else {
        // TODO Fix active/unactive logic
        // eslint-disable-next-line no-lonely-if
        if (!s.active) {
          const tmp = {
            ...s.student,
            status: 'unactive',
          };
          notActive.push(tmp);
        } else {
          const tmp = {
            ...s.student,
            status: 'active',
          };
          active.push(tmp);
        }
      }
      return s;
    });
  }
  sectionsList.push({ title: 'Active', data: active });
  sectionsList.push({ title: 'Unactive', data: notActive });
  sectionsList.push({ title: 'Absent', data: absent });

  return (
    <Container maxWidth="sm" className={classes.container}>
      <div className={classes.title}>
        <Typography variant="h3" style={{ paddingTop: 10 }}>
          Live Attendance
        </Typography>
      </div>
      <Paper className={classes.paper}>
        <div style={{ margin: 10 }}>
          <Typography
            variant="h2"
            style={{
              color: '#0EABBC',
              fontWeight: 'bold',
              fontFamily: 'Arial',
              textAlign: 'center',
            }}
          >
            {secondsToHms(timer)}
          </Typography>
        </div>
        <Button
          style={{
            margin: 20,
            color: '#FFF',
            fontSize: 22,
            fontFamily: 'Arial',
          }}
          size="large"
          variant="contained"
          color="secondary"
          onClick={() => setExit(true)}
        >
          End Lecture
        </Button>
        <Typography
          variant="h4"
          style={{
            color: '#0EABBC',
            fontSize: 24,
            fontWeight: 'bold',
            fontFamily: 'Arial',
          }}
        >
          Classes
        </Typography>
        {items && items.length
          ? items.map((i, index) => (
            <div
              key={index}
              style={{
                width: '100%',
                marginTop: 10,
                padding: 10,
                border: '1px solid #ECFCFD',
              }}
            >
              <Typography
                variant="h4"
                style={{
                  fontSize: 18,
                  fontFamily: 'Arial',
                  verticalAlign: 'middle',
                  color: '#026776',
                }}
              >
                {i.title}
              </Typography>
              <Typography
                variant="h4"
                style={{
                  fontSize: 16,
                  fontFamily: 'Arial',
                  verticalAlign: 'middle',
                  color: '#0EABBC',
                }}
              >
                {i.subtitle}
              </Typography>
            </div>
          ))
          : names.map((i, index) => (
            <div
              key={index}
              style={{
                width: '100%',
                marginTop: 10,
                padding: 10,
                border: '1px solid #ECFCFD',
              }}
            >
              <Typography
                variant="h4"
                style={{
                  fontSize: 18,
                  fontFamily: 'Arial',
                  verticalAlign: 'middle',
                  color: '#026776',
                }}
              >
                {i.title}
              </Typography>
              <Typography
                variant="h4"
                style={{
                  fontSize: 16,
                  fontFamily: 'Arial',
                  verticalAlign: 'middle',
                  color: '#0EABBC',
                }}
              >
                {i.subtitle}
              </Typography>
            </div>
          ))}
        <List
          dense
          sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
        >
          {sectionsList &&
            sectionsList.length &&
            studentsAttLive &&
            studentsAttLive.data &&
            sectionsList.map((s, i) => (
              <div key={i}>
                <ListItem style={{ padding: 0 }}>
                  <ListItemText
                    style={{
                      color: '#0EABBC',
                      backgroundColor: '#ECFCFD',
                      padding: 10,
                      height: 50,
                    }}
                  >
                    <Typography
                      variant="h4"
                      style={{
                        fontSize: 22,
                        fontWeight: 'bold',
                        fontFamily: 'Arial',
                      }}
                    >
                      {s.title}
                    </Typography>
                  </ListItemText>
                </ListItem>
                <List component="div" disablePadding>
                  {s.data.map((d, j) => (
                    <ListItem
                      key={j}
                      style={{
                        height: 80,
                        width: '100%',

                        border: '1px solid #ECFCFD',
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: '#FFF',
                          textAlign: 'left',
                          border: 'none',
                        }}
                      >
                        <ListItemText>
                          <Typography
                            variant="h4"
                            style={{
                              fontSize: 18,
                              fontFamily: 'Arial',
                              verticalAlign: 'middle',
                              color: '#026776',
                            }}
                          >
                            {`${d.roll}   ${d.name}`}
                          </Typography>
                          <Typography
                            variant="h4"
                            style={{
                              fontSize: 16,
                              fontFamily: 'Arial',
                              verticalAlign: 'middle',
                              color: '#0EABBC',
                            }}
                          >
                            {`${d.class.class} ${d.section.section}`}
                          </Typography>
                        </ListItemText>
                      </div>
                    </ListItem>
                  ))}
                </List>
              </div>
            ))}
        </List>
        <Dialog
          fullWidth
          maxWidth="sm"
          open={!live && !bookProgress}
          onClose={() => { }}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogTitle id="max-width-dialog-title">
            Lecture No Longer Live
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              This lecture has ended and you can no longer continue it. Press ok
              to go back.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => history.replace('/teacher')}
              variant="outlined"
              color="primary"
            >
              Continue
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          fullWidth
          maxWidth="sm"
          open={exit}
          onClose={() => setExit(false)}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogTitle id="max-width-dialog-title">
            Stop Live Lecture?
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Your lecure is going on. Are you sure to stop it and go back?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setExit(false)}
              variant="outlined"
              color="primary"
            >
              Cancel
            </Button>
            <Button
              style={{ color: '#FFF' }}
              variant="contained"
              onClick={async () => {
                try {
                  clearInterval(stopInterval);
                  await finishLecture({
                    variables: {
                      attID: `${attID.join(':')}`,
                      timestamp: `${timer}`,
                    },
                  });
                  localStorage.removeItem('live');
                  setExit(false);
                  if (bookIDs.length) {
                    // Set Book Progression Overlay
                    setBookProgress(true);
                  } else {
                    history.replace('/teacher');
                  }
                } catch (e) {
                  console.log(e);
                }
              }}
              color="secondary"
            >
              Continue
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          fullWidth
          maxWidth="sm"
          open={bookProgress}
          onClose={() => { }}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogTitle id="max-width-dialog-title">
            Enter Books Progress
          </DialogTitle>
          <DialogContent>
            {booksData.length && (
              <Formik
                initialValues={{
                  books: booksData.map(b => ({
                    id: b.id,
                    from: b.to,
                    to: '',
                    comment: '',
                    name: b.book.name,
                    bookID: b.book.id,
                  })),
                }}
                validationSchema={Yup.object({
                  books: Yup.array().of(
                    Yup.object().shape({
                      id: Yup.string().required(),
                      from: Yup.string().required(),
                      to: Yup.string().required(),
                      comment: Yup.string(),
                      name: Yup.string(),
                      bookID: Yup.number(),
                    })
                  ),
                })}
              >
                {({ values, handleBlur, handleChange, errors }) => (
                  <FieldArray
                    name="books"
                    render={arrayHelpers => {
                      const { books } = values;
                      return (
                        <div>
                          {books.map((b, i) => (
                            <div
                              style={{
                                margin: 10,
                                borderWidth: 1,
                                borderColor: '#5e6977',
                              }}
                              key={i}
                            >
                              <Typography
                                style={{
                                  fontFamily: 'Montserrat-Bold',
                                  color: '#5e6977',
                                  fontSize: 16,
                                  paddingLeft: 10,
                                }}
                                component="span"
                                variant="body1"
                              >
                                {b.name}
                              </Typography>
                              <div
                                style={{
                                  flex: 1,
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <TextField
                                  margin="normal"
                                  required
                                  variant="outlined"
                                  onChange={handleChange(`books[${i}].from`)}
                                  value={books[i].from.toString()}
                                  name="From"
                                  label="From"
                                />
                                <TextField
                                  margin="normal"
                                  required
                                  variant="outlined"
                                  onChange={handleChange(`books[${i}].to`)}
                                  value={books[i].to}
                                  label="To"
                                  name="To"
                                />
                              </div>
                              <TextField
                                margin="normal"
                                required
                                variant="outlined"
                                onChange={handleChange(`books[${i}].comment`)}
                                value={books[i].comment}
                                label="Comment"
                                name="Comment"
                              />
                            </div>
                          ))}
                          <Button
                            onClick={async () => {
                              try {
                                const bData = [];
                                books.map(v => {
                                  const t = {
                                    to: parseInt(v.to),
                                    from: v.from,
                                    noOfPages: parseInt(v.to) - v.from,
                                    bookID: v.bookID,
                                    termID: school.termID,
                                    teacherID: activeTeacher.id || user.id,
                                    comment: v.comment,
                                    attID: attIDbookID[v.bookID.toString()],
                                  };
                                  bData.push(t);
                                  return v;
                                });
                                await saveBooksProgress({
                                  variables: {
                                    data: bData,
                                  },
                                });
                                setBookProgress(false);
                                setBookIDs([]);
                                setBooksData([]);
                                setAttIDbookID({});
                                history.replace('/teacher');
                              } catch (e) {
                                console.log(e);
                              }
                            }}
                            color="secondary"
                            variant="contained"
                          >
                            Continue
                          </Button>
                        </div>
                      );
                    }}
                  />
                )}
              </Formik>
            )}
          </DialogContent>
        </Dialog>
        <Copyright />
      </Paper>
    </Container>
  );
}
