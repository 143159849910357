import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import React from 'react';
import { useReactToPrint } from 'react-to-print';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    paddingLeft: 40,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h5">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
const useStyle = makeStyles(theme => ({
  button: {
    color: '#FFF',
  },
  payment: {
    marginBottom: 100,
  },
}));
export default function PaymentDialog(props) {
  const { open, handleClose, data, school } = props;
  const classes = useStyle();
  const receiptRef = React.useRef();
  const [print, setPrint] = React.useState(false);
  const student = {
    name: data.student.name,
    term: data.term,
  };
  const printReciept = useReactToPrint({
    content: () => receiptRef.current,
    onBeforeGetContent: () => setPrint(true),
    onAfterPrint: () => setPrint(false),
  });
  let totalPaid = 0;
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          View / Print Payment Reciept
        </DialogTitle>
        <DialogContent>
          <div
            style={
              school.images[0]
                ? {
                  paddingLeft: 20,
                  paddingRight: 20,
                  minHeight: '100%',
                  marginTop: 20,
                  marginBottom: 100,
                  // background: `url(${school.images[0].url}) no-repeat center`,
                  // backgroundSize: `contain`,
                }
                : {
                  paddingLeft: 20,
                  paddingRight: 20,
                  marginTop: 20,
                  marginBottom: 100,
                  minHeight: '100%',
                }
            }
            ref={receiptRef}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexGrow: 1,
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div style={{ flex: 0.75 }}>
                <span
                  style={{
                    fontSize: 28,
                    fontWeight: 'bold',
                  }}
                >
                  {school.name}
                </span>
                <br />
                <span
                  style={{
                    fontSize: 20,
                    fontWeight: 'bold',
                  }}
                >
                  {student.name} - {student.term}
                </span>
                <br />
                <span
                  style={{
                    fontSize: 20,
                    fontWeight: 'bold',
                  }}
                >
                  Receipt No: {data.receiptno}
                </span>
                <br />
              </div>
              <div style={{ flex: 0.25 }}>
                {school.logo && (
                  <img src={school.logo} alt="" height={100} width={100} />
                )}
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingRight: 20,
                border: '1px solid grey',
                padding: 5,
                fontWeight: 'bold',
                fontSize: 12,
                textTransform: 'uppercase',
              }}
            >
              <span style={{ flex: 0.2 }}>Fee Name</span>
              <span style={{ flex: 0.15 }}>Type</span>
              <span style={{ flex: 0.15 }}>Amount</span>
              <span style={{ flex: 0.2 }}>Status</span>
              <span style={{ flex: 0.2 }}>Cheque No</span>
              <span style={{ flex: 0.2 }}>Paid On</span>
              <span style={{ flex: 0.2 }}>Collected By</span>
              <span style={{ flex: 0.15 }}>Pending</span>
            </div>
            {data.fees_collecteds.map((s, index) => {
              totalPaid += s.feesStatus === 'FAILED' ? 0 : s.amount;
              return (
                <div
                  key={`div${index}`}
                  style={
                    print
                      ? {
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingRight: 20,
                        border: '1px solid grey',
                        padding: 2,
                        fontSize: '8 !important',
                      }
                      : {
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingRight: 20,
                        border: '1px solid grey',
                        padding: 5,
                      }
                  }
                >
                  <span style={{ flex: 0.2 }}>{s.fee.name}</span>
                  <span style={{ flex: 0.15 }}>
                    {s.fee.month || 'One Time'}
                  </span>
                  <span style={{ flex: 0.15 }}>&#8377; {s.amount}</span>

                  <span style={{ flex: 0.2 }}>{s.feesStatus}</span>
                  <span style={{ flex: 0.2 }}>{s.chequeNo}</span>
                  <span style={{ flex: 0.2 }}>
                    {moment(s.collectedAt).format('DD/MM/YY hh:mm a')}
                  </span>
                  <span style={{ flex: 0.2 }}>{s.user.name} </span>
                  <span style={{ flex: 0.15, fontWeight: 'bold' }}>
                    &#8377;{' '}
                    {s.feesStatus === 'FAILED'
                      ? s.fee.amount
                      : s.fee.amount - s.amount}
                  </span>
                </div>
              );
            })}
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginTop: 20,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  gap: 20,
                  marginTop: 20,
                }}
              >
                <h4>Total Paid: &#8377; {totalPaid}</h4>
                <h4>
                  Payment Mode:{' '}
                  {data.onlineRef
                    ? 'Online'
                    : data.chequeNo
                      ? 'Cheque'
                      : 'Cash'}
                </h4>
                {data.onlineRef && (
                  <h4>Online Payment Ref: {data.onlineRef}</h4>
                )}
              </div>
              {!print && (
                <Button
                  color="secondary"
                  variant="contained"
                  className={classes.button}
                  onClick={() => {
                    printReciept();
                  }}
                >
                  Print Reciept
                </Button>
              )}
            </div>
            <h4>Signature and School Stamp</h4>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
