import { useApolloClient, useMutation } from '@apollo/client';
import { Box } from '@material-ui/core';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React from 'react';
import { PAYMENTS_COLLECTED_QUERY } from '../resolvers/Queries';
import Loader from './Loader';
import Table, { tableIcons } from './Table';
// import ClassSectionSubjectDialog from './ClassSectionSubjectDialog';
import { DELETE_FEES_COLLECTED_MUTATION } from '../resolvers/Mutations';
import PaymentDialog from './PaymentDialog';
import TermsContext from './TermsContext';

export default function ViewFeesEntries(props) {
  const { school, user } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [currentPayment, setCurrentPayment] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [payments, setPayments] = React.useState({});
  const [feesRows, setFeesRows] = React.useState([]);
  const { terms } = React.useContext(TermsContext);
  const client = useApolloClient();
  let feesRes;
  // const [dialogOpen, setDialogClose] = React.useState(false);
  // const [rowD, setRowD] = React.useState({});
  // const closeDialog = () => setDialogClose(false);
  const [deleteFee, deleteFeeData] = useMutation(
    DELETE_FEES_COLLECTED_MUTATION
  );
  React.useEffect(() => {
    const getFees = async () => {
      try {
        // eslint-disable-next-line
        feesRes = await client.query({
          query: PAYMENTS_COLLECTED_QUERY,
          variables: { schoolID: school.id },
          fetchPolicy: 'network-only',
          notifyOnNetworkStatusChange: true,
        });
        const tmpPayments = {};
        const tmpRows = feesRes.data.receipt_view
          .filter(p => {
            const f = p.payment;
            if (f.fees_collecteds.length === 0) {
              console.log(
                f.id,
                moment(p.collectedAt).format('DD/MM/YY hh:mm a')
              );
            }
            return f.fees_collecteds.length;
          })
          .map((p, i) => {
            const f = p.payment;
            console.log(f.onlineRef);
            const tmp = {
              sr: i + 1,
              receiptno: p.receiptno,
              id: f.id,
              amount: f.amount,
              chequeNo: f.fees_collecteds[0].chequeNo || '',
              paymentRef: f.onlineRef || '',
              collectedAt: moment(p.collectedAt).format('DD/MM/YY hh:mm a'),
              createdAt: moment(p.createdAt).format('DD/MM/YY hh:mm a'),
              collectedBy: f.fees_collecteds[0].user.name,
              student: f.student.name,
              class: f.student.class.class,
              section: f.student.section.section,
              registerNo: f.student.registerNo,
              term: terms.filter(t => t.id === p.termID)[0].name || '',
            };
            tmpPayments[f.id] = {
              receiptno: p.receiptno,
              ...f,
              term: tmp.term,
            };
            return tmp;
          });
        setFeesRows(tmpRows);
        setPayments(tmpPayments);
      } catch (e) {
        console.log(e);
        enqueueSnackbar(`Something Went Wrong. Heres the error ${e.message}`, {
          variant: 'error',
          anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
        });
      }
      setLoading(false);
    };
    getFees();
  }, [school]);

  const handleClose = () => setCurrentPayment(false);
  if (deleteFeeData.loading || loading) return <Loader />;
  return (
    <Box>
      <Table
        columns={[
          {
            title: 'Sr.No.',
            field: 'sr',
            cellStyle: {
              whiteSpace: 'nowrap',
            },
          },
          { title: 'Id', field: 'id', hidden: true },
          {
            title: 'Reciept No',
            field: 'receiptno',
            cellStyle: {
              whiteSpace: 'nowrap',
            },
          },
          {
            title: 'Entry Date',
            field: 'collectedAt',
            cellStyle: {
              whiteSpace: 'nowrap',
            },
          },
          {
            title: 'Student',
            field: 'student',
            cellStyle: {
              whiteSpace: 'nowrap',
            },
          },
          {
            title: 'Term',
            field: 'term',
            cellStyle: {
              whiteSpace: 'nowrap',
            },
          },
          { title: 'Class', field: 'class' },
          { title: 'Section', field: 'section' },
          { title: 'GR.NO', field: 'registerNo' },
          {
            title: 'Amount',
            field: 'amount', // eslint-disable-next-line react/display-name
          },
          { title: 'Cheque No', field: 'chequeNo' },
          { title: 'Online Pay Ref', field: 'paymentRef' },
          {
            title: 'Collected By',
            field: 'collectedBy',
            cellStyle: {
              whiteSpace: 'nowrap',
            },
          },
          {
            title: 'Collected At',
            field: 'createdAt',
            cellStyle: {
              whiteSpace: 'nowrap',
            },
          },
        ]}
        data={feesRows}
        title="Fees Collected"
        actions={
          user.permissions === 'ADMIN' || user.permissions === 'SUPERADMIN'
            ? [
              {
                icon: tableIcons.ViewColumn,
                tooltip: 'View Payment',
                onClick: (event, rowData) => {
                  console.log(payments[rowData.id]);
                  setCurrentPayment(payments[rowData.id]);
                },
              },
              rowData => ({
                icon: tableIcons.Delete,
                tooltip: 'Delete Fee',
                onClick: async (event, d) => {
                  try {
                    if (
                      !window.confirm(
                        'Are you sure you want to delete this fee?'
                      )
                    )
                      return;
                    if (rowData.paymentRef) {
                      alert(
                        'You can not delete this fee as it has been paid online'
                      );
                      return;
                    }
                    await deleteFee({
                      variables: {
                        id: rowData.id,
                      },
                    });
                    await feesRes.refetch();
                    enqueueSnackbar('Fee Deleted Successfully!', {
                      variant: 'success',
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                    });
                  } catch (e) {
                    console.log(e);
                  }
                },
              }),
            ]
            : []
        }
      />
      {currentPayment !== false && (
        <PaymentDialog
          open={currentPayment !== false}
          handleClose={handleClose}
          data={currentPayment}
          school={school}
        />
      )}
    </Box>
  );
}
