import { useQuery } from '@apollo/client';
import { Container, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { ACCOUNTS_QUERY } from '../resolvers/Queries';
import AccountDialog from './AccountDialog';
import AddAccountForm from './AddAccountForm';
import Loader from './Loader';
import Table, { tableIcons } from './Table';
import UserContext from './UserContext';

const useStyles = makeStyles(theme => ({
  wrapper: {
    width: '48%',
  },
  content: {
    padding: 10,
  },
  main: {
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: '100vh',
  },
}));
export default function AccountSettings(props) {
  const [dialogOpen, setDialogClose] = React.useState(false);
  const [rowD, setRowD] = React.useState({});
  const closeDialog = () => setDialogClose(false);
  const { school } = props;
  const { user } = React.useContext(UserContext);
  const classes = useStyles();
  const accData = useQuery(ACCOUNTS_QUERY, {
    variables: { schoolID: school.id },
    fetchPolicy: 'network-only',
  });

  if (accData.loading) return <Loader />;
  const accs = accData.data.accounts.map((a, i) => {
    const tmp = {
      sr: i + 1,
      name: a.name,
      accNumber: a.accNumber,
      ifsc: a.ifsc,
      id: a.id,
    };
    return tmp;
  });
  return (
    <Container className={classes.main} maxWidth="xl" disableGutters>
      <Paper className={classes.wrapper}>
        <Table
          columns={[
            { title: 'Sr.No.', field: 'sr', width: '5%' },
            { title: 'id', field: 'id', width: '5%' },
            { title: 'Name', field: 'name', width: '50%' },
            { title: 'Account Number', field: 'accNumber', hidden: true },
            { title: 'IFSC Code', field: 'ifsc', hidden: true },
          ]}
          actions={[
            {
              icon: tableIcons.Edit,
              tooltip: 'Edit User',
              onClick: (event, rowData) => {
                setRowD(rowData);
                setDialogClose(true);
              },
            },
          ]}
          data={accs}
          title="Bank Details"
        />
      </Paper>
      <Paper className={classes.wrapper}>
        {user.permissions === 'SUPERADMIN' && (
          <AddAccountForm school={school} user={user} />
        )}
      </Paper>
      <AccountDialog
        open={dialogOpen}
        handleClose={closeDialog}
        data={rowD}
        update
        school={school}
        closeMain=""
      />
    </Container>
  );
}
