import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import React from 'react';
import AddAccountForm from './AddAccountForm';

export default function AccountDialog(props) {
  const { open, handleClose, data, school } = props;

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogContent>
          <AddAccountForm
            update={data}
            school={school}
            closeMain={handleClose}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
