import { useApolloClient, useQuery } from '@apollo/client';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import { useTheme } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import AttReports from '../components/AttReports';
import FeesReports from '../components/FeesReports';
import Layout from '../components/Layout';
import Loader from '../components/Loader';
import QrReports from '../components/QrReports';
import UserContext from '../components/UserContext';
import {
  GET_SCHOOL_QUERY,
  GET_SECTIONS_QUERY,
  GET_TERMS_QUERY,
} from '../resolvers/Queries';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function Reports() {
  const { user } = React.useContext(UserContext);
  const { schoolID } = user;
  const [school, setSchool] = React.useState({});
  const [sections, setSections] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const client = useApolloClient();
  const yearsData = useQuery(GET_TERMS_QUERY, {
    variables: { schoolID },
  });
  useQuery(GET_SCHOOL_QUERY, {
    variables: { id: schoolID },
    onCompleted: async d => {
      setSchool(d.schools_by_pk);
      const cls = await client.query({
        query: GET_SECTIONS_QUERY,
        variables: {
          id: schoolID,
          currentTerm: d.schools_by_pk.termID,
        },
      });
      setSections(
        cls.data.sections.map(s => ({
          value: s.id,
          label: `${s.class.class} ${s.section}`,
        }))
      );
      setLoading(false);
    },
  });
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const handleChange = (event = '', newValue) => {
    setValue(newValue);
  };
  if (loading || yearsData.loading) return <Loader />;
  return (
    <Layout>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          // variant="fullWidth"
          centered
          aria-label="full width tabs example"
        >
          <Tab label="QR Reports" {...a11yProps(0)} />
          <Tab label="Attendance Reports" {...a11yProps(1)} />
          <Tab label="Fees Reports" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      {school.qrcode && (
        <TabPanel value={value} index={0} dir={theme.direction}>
          <QrReports
            school={school}
            sections={sections}
            years={yearsData.data.terms.map(t => ({
              value: t.id,
              label: t.name,
            }))}
          />
        </TabPanel>
      )}
      <TabPanel value={value} index={1} dir={theme.direction}>
        <AttReports
          school={school}
          sections={sections}
          years={yearsData.data.terms.map(t => ({
            value: t.id,
            label: t.name,
          }))}
        />
      </TabPanel>
      <TabPanel value={value} index={2} dir={theme.direction}>
        <FeesReports
          school={school}
          sections={sections}
          years={yearsData.data.terms.map(t => ({
            value: t.id,
            label: t.name,
          }))}
        />
      </TabPanel>
    </Layout>
  );
}
