import { useMutation, useQuery } from '@apollo/client';
import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import React from 'react';
import {
  DELETE_BOOK_MUTATION,
  DELETE_SUBJECT_MUTATION,
} from '../resolvers/Mutations';
import { GET_SUBJECTS_QUERY } from '../resolvers/Queries';
import BookDialog from './BookDialog';
import Loader from './Loader';
import SubjectDialog from './SubjectDialog';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: 10,
    padding: 20,
  },
  main: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  wrapper: {
    width: '50%',
    padding: 20,
  },
  button: {
    height: 35,
    color: '#666',
    fontWeight: 'bold',
  },
  subjectList: {
    marginTop: 20,
    padding: 20,
  },
  mainView: {
    border: '1px solid grey',
    padding: 10,
    margin: 5,
  },
  subjectView: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  bookTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  bookView: {
    marginTop: 5,
    paddingLeft: 10,
  },
  bookLine: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: '1px solid grey',
    padding: 2,
    paddingLeft: 5,
  },
}));
export default function SubjectsView(props) {
  const classes = useStyles();
  const { school, sec } = props;
  const [bookDialog, setBookDialog] = React.useState(false);
  const [subjectDialog, setSubjectDialog] = React.useState(false);
  const [subject, setSubject] = React.useState(false);
  const [book, setBook] = React.useState(false);
  const closeSubjectDialog = () => setSubjectDialog(false);
  const closeBookDialog = () => setBookDialog(false);
  const [deleteSubject, deleteSubjectRes] = useMutation(
    DELETE_SUBJECT_MUTATION
  );
  const [deleteBook, deleteBookRes] = useMutation(DELETE_BOOK_MUTATION);
  const subsRes = useQuery(GET_SUBJECTS_QUERY, {
    variables: {
      sectionID: sec.id,
    },
    fetchPolicy: 'network-only',
  });

  if (subsRes.loading || deleteSubjectRes.loading || deleteBookRes.loading)
    return <Loader />;
  let ts = {};
  sec.section_teachers.map(t => {
    ts[t.teacherID] = {
      label: `${t.user.name} | ${t.user.email}`,
      value: t.teacherID,
    };
    return t;
  });
  ts = Object.values(ts);
  return (
    <div>
      <div className={classes.main}>
        <Typography variant="h4" component="h4" color="primary">
          Curriculum For {sec.class.class} {sec.section}
        </Typography>
        {/* <Button
          type="submit"
          color="secondary"
          variant="outlined"
          className={classes.button}
          startIcon={<AddIcon />}
          onClick={() => setSubjectDialog(true)}
        >
          {update ? 'Update Subject' : 'Add Subject'}
        </Button> */}
      </div>
      <div className={classes.subjectList}>
        {subsRes.data.subjects && subsRes.data.subjects.length > 0 ? (
          subsRes.data.subjects.map((s, index) => (
            <div key={index}>
              <div className={classes.mainView}>
                <div className={classes.subjectView}>
                  <div>
                    <Typography variant="h6" component="h6" color="primary">
                      Sub: {s.subject}
                    </Typography>
                    <Typography variant="body1" color="primary">
                      Teachers Assigned:{' '}
                      {s.subject_teachers.map((t, i) => (
                        <span key={i}>
                          {i > 0 ? ', ' : ''}
                          {t.user.name}
                        </span>
                      ))}
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <div style={{ cursor: 'pointer', width: 35 }}>
                      <EditIcon
                        color="primary"
                        onClick={() => {
                          setSubject(s);
                          setSubjectDialog(true);
                        }}
                      />
                    </div>
                    <div style={{ cursor: 'pointer', width: 35 }}>
                      <DeleteIcon
                        color="primary"
                        onClick={async () => {
                          try {
                            await deleteSubject({
                              variables: { subjectID: s.id },
                            });
                            await subsRes.refetch();
                          } catch (e) {
                            console.log(e);
                          }
                          setSubject(s);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div className={classes.bookTitle}>
                    <Typography variant="h6" color="primary">
                      Books
                    </Typography>
                    <Button
                      type="submit"
                      color="secondary"
                      variant="outlined"
                      className={classes.button}
                      startIcon={<AddIcon />}
                      onClick={() => {
                        setSubject(s);
                        setBookDialog(true);
                      }}
                    >
                      Add Book
                    </Button>
                  </div>
                  <div className={classes.bookView}>
                    {s.books && s.books.length > 0 ? (
                      s.books.map((b, i) => (
                        <div key={i}>
                          <div className={classes.bookLine}>
                            <div>
                              <Typography variant="body1" color="primary">
                                {`${b.name}`}
                              </Typography>
                              <div style={{ paddingLeft: 5 }}>
                                <Typography variant="body2" color="primary">
                                  Teachers:{' '}
                                  {b.books_teachers
                                    .map(t => t.user.name)
                                    .join(',')}
                                </Typography>
                              </div>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                              }}
                            >
                              <div style={{ cursor: 'pointer', width: 35 }}>
                                <EditIcon
                                  color="primary"
                                  onClick={() => {
                                    setSubject(s);
                                    setBook(b);
                                    setBookDialog(true);
                                  }}
                                />
                              </div>
                              <div style={{ cursor: 'pointer', width: 35 }}>
                                <DeleteIcon
                                  color="primary"
                                  onClick={async () => {
                                    try {
                                      await deleteBook({
                                        variables: { bookID: b.id },
                                      });
                                      await subsRes.refetch();
                                    } catch (e) {
                                      console.log(e);
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <h4>No Books Added Yet</h4>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <h4>No Subjects Added Yet</h4>
        )}
      </div>
      <SubjectDialog
        open={subjectDialog}
        handleClose={closeSubjectDialog}
        subject={subject}
        update={!!subject}
        school={school}
        sec={sec}
        teachers={ts}
      />
      <BookDialog
        open={bookDialog}
        handleClose={closeBookDialog}
        book={book}
        update={!!book}
        school={school}
        subject={subject}
      />
    </div>
  );
}
