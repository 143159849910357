/* eslint-disable camelcase */
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Container,
  Divider,
  Paper,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as moment from 'moment';
import { useSnackbar } from 'notistack';
import React from 'react';
import * as yup from 'yup';
import {
  CREATE_CLASS_MUTATION,
  CREATE_SECTIONS_MUTATION,
} from '../resolvers/Mutations';
import {
  CLASSES_QUERY,
  GET_SECTIONS_QUERY,
  GET_USERS_QUERY,
} from '../resolvers/Queries';
import Loader from './Loader';
import UploadData from './UploadData';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  radio: {
    width: '100%',
    marginBottom: 10,
  },
  options: {
    width: '100%',
    marginBottom: 10,
  },
  break: {
    flexBasis: '100%',
    height: 0,
  },
  wrapper: {
    width: '48%',
  },
  content: {
    padding: 10,
  },
  main: {
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: '100vh',
  },
  button: {
    marginTop: 30,
  },
}));
export default function BulkAddSections(props) {
  const classes = useStyles();
  const { school } = props;
  const client = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const [sections, setSections] = React.useState([]);
  const [submitEnabled, setsubmitEnabled] = React.useState(false);
  const clsRes = useQuery(CLASSES_QUERY, {
    variables: {
      schoolID: school.id,
      termID: school.termID,
    },
  });
  const [createClass, createClassRes] = useMutation(CREATE_CLASS_MUTATION);
  const [createSections, createSectionsRes] = useMutation(
    CREATE_SECTIONS_MUTATION
  );
  const teachersRes = useQuery(GET_USERS_QUERY, {
    variables: { id: school.id },
  });
  const schema = yup.object().shape({
    sr: yup.number(),
    class: yup.string().required(),
    section: yup.string().required(),
    teachers: yup.array(),
    notificationTime: yup.date(),
    fbID: yup.string(),
  });
  const sectionsImport = [
    ['sr', 'class', 'section', 'teachers', 'notificationTime', 'fbID'],
    ['', '', ''],
    ['', '', ''],
    ['', '', ''],
    ['', '', ''],
    ['', '', ''],
  ];

  const getTeachersIDs = teachers => {
    const { users } = teachersRes.data;
    const tIDs = [];
    teachers.map(t => {
      // eslint-disable-next-line prefer-destructuring
      const exist = users.filter(u => u.email === t);
      if (exist.length > 0) tIDs.push({ teacherID: exist[0].id });
      return t;
    });
    if (tIDs.length === 0) return false;
    return tIDs;
  };
  const getClassID = cl =>
    // eslint-disable-next-line no-async-promise-executor
    new Promise(async resolve => {
      const data = await client.readQuery({
        query: CLASSES_QUERY,
        variables: {
          schoolID: school.id,
          termID: school.termID,
        },
      });
      const exist = data.classes.filter(
        x => x.class.toLowerCase() === cl.toLowerCase()
      );
      if (exist.length > 0) {
        resolve(exist[0].id);
      } else {
        const c = {
          class: cl,
          termID: school.termID,
          schoolID: school.id,
        };

        const newID = await createClass({
          variables: { data: c },
          update: (cache, { data: { insert_classes_one } }) => {
            cache.writeQuery({
              query: CLASSES_QUERY,
              variables: { schoolID: school.id, termID: school.termID },
              data: {
                ...data,
                classes: [...data.classes, insert_classes_one],
              },
            });
          },
        });
        resolve(newID.data.insert_classes_one.id);
      }
    });
  const handleSubmit = async () => {
    const data = [];
    for (const section of sections) {
      const classID = await getClassID(section.class);
      section.classID = classID;
      delete section.class;
      section.notificationTime = section.notificationTime.format('HH:mm:ssZ');
      data.push(section);
    }
    try {
      await createSections({
        variables: { data },
        refetchQueries: [
          {
            query: GET_SECTIONS_QUERY,
            variables: {
              id: school.id,
              currentTerm: school.termID,
            },
          },
        ],
      });
      enqueueSnackbar(`Sections Successfully Imported`, {
        variant: 'success',
        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
      });
    } catch (e) {
      enqueueSnackbar(`Error in Apollo Action. ${e.message}`, {
        variant: 'error',
        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
      });
    }
  };
  const handleForce = (da, fileName) => {
    const sectionsArray = [];
    for (const section of da) {
      const s = {
        sr: parseInt(section.sr),
        class: section.class,
        section: section.section,
        teachers: section.teachers.split('|'),
        notificationTime: section.notificationtime
          ? moment()
            .hour(section.notificationtime.split(':')[0])
            .minute(section.notificationtime.split(':')[1])
            .second('0')
          : moment(),
        fbID: section.fbid,
      };
      schema.isValid(s).then(function (valid) {
        if (valid) {
          const teachersIDs = getTeachersIDs(s.teachers);
          if (teachersIDs.length < 1) {
            enqueueSnackbar(
              `${section.teachers} Missing. Please Add Them First`,
              {
                variant: 'error',
                anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
              }
            );
          } else {
            const tmp = {
              sr: s.sr,
              section: s.section,
              class: s.class,
              fbID: s.fbID,
              notificationTime: s.notificationTime,
              termID: school.termID,
              section_teachers: {
                data: teachersIDs,
              },
              schoolID: school.id,
            };
            sectionsArray.push(tmp);
          }
        } else {
          enqueueSnackbar(
            'Some field is not valid. Please Recheck The File And Try Again',
            {
              variant: 'error',
              anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
            }
          );
        }
      });
    }
    setSections(sectionsArray);
    setsubmitEnabled(true);
    enqueueSnackbar('File looks good. Please click submit to import.', {
      variant: 'success',
      anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
    });
  };
  if (
    teachersRes.loading ||
    clsRes.loading ||
    createClassRes.loading ||
    createSectionsRes.loading
  )
    return <Loader />;
  if (
    teachersRes.error ||
    clsRes.error ||
    createClassRes.error ||
    createSectionsRes.error
  ) {
    console.log(
      teachersRes.error,
      clsRes.error,
      createClassRes.error,
      createSectionsRes.error
    );
    enqueueSnackbar(`Error in Apollo Action. Check console for more info.`, {
      variant: 'error',
      anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
    });
  }
  return (
    <Container className={classes.main} maxWidth="xl" disableGutters>
      <Paper className={classes.wrapper}>
        <div className={classes.content}>
          <UploadData
            template={sectionsImport}
            filename="SectionsTemplate.csv"
            checkData={handleForce}
            title="Upload CSV file for Class and Section import"
          />
          <Divider />
          <Button
            className={classes.button}
            variant="contained"
            color="secondary"
            onClick={handleSubmit}
            disabled={!submitEnabled}
          >
            Upload Sections
          </Button>
        </div>
      </Paper>
      <Paper className={classes.wrapper}>
        <Typography className={classes.content} component="h1" variant="h5">
          Usage Guide
        </Typography>
        <Divider />
        <Typography className={classes.content} gutterBottom variant="body1">
          1 - Please make sure the file you are uploading has the following 5
          fields: sr, class, section, teachers, notificationTime.
        </Typography>
        <Typography className={classes.content} gutterBottom variant="body1">
          2 - If bulk uploading classes not previously added will be added.
          Please make sure that spelling and capitilization are the same to
          avoid repeat imports.
        </Typography>
        <Typography className={classes.content} gutterBottom variant="body1">
          3 - Teachers need to have been uploaded before bulk uploading classes
          and sections. Otherwise import will fail.
        </Typography>
        <Typography className={classes.content} gutterBottom variant="body1">
          4 - Multiple teachers for one section should be kept in one cell
          separated with a | character.
        </Typography>
        <Typography className={classes.content} gutterBottom variant="body1">
          5 - Teachers field can only be the teachers email id.
        </Typography>
      </Paper>
    </Container>
  );
}
