import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/messaging';
import { SnackbarProvider } from 'notistack';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';
import App from './App';
import Loader from './components/Loader';
import SchoolContext from './components/SchoolContext';
import TermsContext from './components/TermsContext';
import UserContext from './components/UserContext';
import Buses from './pages/Buses';
import Classes from './pages/Classes';
import DailyAttendances from './pages/DailyAttendances';
import Dashboard from './pages/Dashboard';
import Expenses from './pages/Expenses';
import Fees from './pages/Fees';
import FeesRedirect from './pages/FeesRedirect';
import OnlineFees from './pages/OnlineFees';
import OnlineFeesSelect from './pages/OnlineFeesSelect';
import Promotions from './pages/Promotions';
import Reports from './pages/Reports';
import Schools from './pages/Schools';
import Settings from './pages/Settings';
import SignIn from './pages/SignIn';
import StudentLectures from './pages/StudentLectures';
import StudentLive from './pages/StudentLive';
import Students from './pages/Students';
import Teacher from './pages/Teacher';
import TeacherLive from './pages/TeacherLive';
import Users from './pages/Users';
import VerifyOnlineFees from './pages/VerifyOnlineFees';
import { ADD_TOKEN_MUTATION } from './resolvers/Mutations';
import {
  GET_SCHOOL_QUERY,
  GET_STUDENTS_BY_LOGIN,
  GET_TEACHER_QUERY,
} from './resolvers/Queries';
import { registerServiceWorker } from './serviceWorker';
import theme from './utils/theme';

const API = 'https://pg.sstracker.in/v1/graphql'; // LOCAL ENV
// const API = 'http://localhost:8080/v1/graphql'; // LOCAL ENV
const firebaseConfig = {
  apiKey: 'AIzaSyDs_k93IIcFaoz--PwzdGjA0Iki1oMEWVM',
  authDomain: 'sama-11841.firebaseapp.com',
  databaseURL: 'https://sama-11841.firebaseio.com',
  projectId: 'sama-11841',
  storageBucket: 'sama-11841.appspot.com',
  messagingSenderId: '320459962778',
  appId: '1:320459962778:web:df5c62a8841619d2ab2223',
};
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  firebase.messaging();
}
class Index extends Component {
  httpLink = new HttpLink({
    uri: API,
  });

  authLink = setContext(async () => {
    const token = await firebase.auth().currentUser.getIdToken();
    return {
      headers: {
        Authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  wsLink = new WebSocketLink({
    uri: `wss://pg.sstracker.in/v1/graphql`,
    // uri: `wss://localhost:8080/v1/graphql`,
    options: {
      reconnect: true,
      lazy: true,
      connectionParams: async () => {
        const token = await firebase.auth().currentUser.getIdToken();
        return {
          headers: {
            Authorization: token ? `Bearer ${token}` : '',
          },
        };
      },
    },
  });

  splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    this.wsLink,
    this.httpLink
  );

  client = new ApolloClient({
    link: this.authLink.concat(this.splitLink),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            receipt_view: {
              merge: false,
            },
          },
        },
      },
    }),
  });

  altCLient = new ApolloClient({
    link: this.httpLink,
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            receipt_view: {
              merge: false,
            },
          },
        },
      },
    }),
  });

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  async componentDidMount() {
    this.askForPermissioToReceiveNotifications = async () => {
      try {
        const messaging = firebase.messaging();
        await messaging.requestPermission();
        const token = await messaging.getToken();
        const students = JSON.parse(localStorage.getItem('students'));
        const data = [];
        students.map(s => {
          const t = {
            token,
            studentID: s.id,
            schoolID: s.schoolID,
          };
          data.push(t);
          return s;
        });
        await this.client.mutate({
          mutation: ADD_TOKEN_MUTATION,
          variables: {
            data,
            conflict_rule: {
              constraint: 'token_studentID_key',
              update_columns: ['token'],
            },
          },
        });

        return token;
      } catch (error) {
        console.error(error);
      }
    };

    // await firebase.auth().signOut();
    this.unsubscribe = firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        let idTokenResult = await user.getIdTokenResult();
        let claims = idTokenResult.claims['https://hasura.io/jwt/claims'];
        if (!claims) {
          // const token = await user.getIdToken(true);
          idTokenResult = await user.getIdTokenResult();
          claims = idTokenResult.claims['https://hasura.io/jwt/claims'];
        }
        const userID = claims['x-hasura-user-id'];
        const userType = claims['x-hasura-default-role'];
        let u;
        let schoolD = {
          id: null,
        };
        if (userType === 'STUDENT') {
          try {
            const studentsData = await this.client.query({
              query: GET_STUDENTS_BY_LOGIN,
              variables: {
                email: user.email,
              },
            });
            if (studentsData.data.students.length === 0) {
              this.setState({
                user: { id: null },
                loading: false,
              });
            } else {
              // Save all gotten students in AsyncStorage
              const jsonValue = JSON.stringify(studentsData.data.students);
              localStorage.setItem('students', jsonValue);
              this.askForPermissioToReceiveNotifications();
              u = {
                name: studentsData.data.students[0].name,
                id: studentsData.data.students[0].id,
                sectionID: studentsData.data.students[0].sectionID,
                classID: studentsData.data.students[0].classID,
                roll: studentsData.data.students[0].roll,
                email: studentsData.data.students[0].loginEmail,
                schoolID: claims['x-hasura-school-id'],
                permissions: userType,
              };
              schoolD = {
                id: studentsData.data.students[0].school.id,
                name: studentsData.data.students[0].school.name,
                termID: studentsData.data.students[0].school.termID,
                terms: studentsData.data.students[0].school.terms,
              };
            }
          } catch (e) {
            console.log(e);
          }
          // Do something with student
        } else if (userType === 'TEACHER') {
          const teacherData = await this.client.query({
            query: GET_TEACHER_QUERY,
            variables: {
              userID,
            },
          });
          u = {
            name: teacherData.data.users_by_pk.name,
            phone: teacherData.data.users_by_pk.phone,
            zoomID: teacherData.data.users_by_pk.zoomID,
            zoomPassword: teacherData.data.users_by_pk.zoomPassword,
            zoomLink: teacherData.data.users_by_pk.zoomLink,
            id: claims['x-hasura-user-id'],
            email: user.email,
            schoolID:
              claims['x-hasura-school-id'] === '0' // This only for SUPERADMIN DEV
                ? 'ae49345a-2f96-4d83-8e60-d8550e407fd2'
                : claims['x-hasura-school-id'],
            // schoolID: claims['x-hasura-school-id'],
            permissions: claims['x-hasura-default-role'],
          };
          schoolD = {
            id: teacherData.data.users_by_pk.school.id,
            name: teacherData.data.users_by_pk.school.name,
            termID: teacherData.data.users_by_pk.school.termID,
            terms: teacherData.data.users_by_pk.school.terms,
          };
        } else {
          u = {
            id: claims['x-hasura-user-id'],
            email: user.email,
            schoolID:
              claims['x-hasura-school-id'] === '0' // This only for SUPERADMIN DEV
                ? 'ae49345a-2f96-4d83-8e60-d8550e407fd2'
                : claims['x-hasura-school-id'],
            // schoolID: claims['x-hasura-school-id'],
            permissions: claims['x-hasura-default-role'],
          };

          if (u.schoolID !== '0') {
            const schoolData = await this.client.query({
              query: GET_SCHOOL_QUERY,
              variables: {
                id: u.schoolID,
              },
            });
            if (schoolData.data.schools_by_pk) {
              schoolD = schoolData.data.schools_by_pk;
            }
          }
        }

        this.setState({
          user: u,
          school: schoolD,
          terms: schoolD.terms,
          currentTerm: schoolD.termID,
          loading: false,
        });
      } else {
        this.setState({
          loading: false,
          user: { id: null },
          school: { id: null },
        });
      }
    });
  }

  componentWillUnmount() {
    console.log('Hittgin unmount');
    this.unsubscribe();
    this.onMessageListener();
    this.onAuthStateChanged();
    this.askForPermissioToReceiveNotifications();
  }

  setUser = user => {
    this.setState({ user });
  };

  setSchool = school => {
    this.setState({ school });
  };

  setTerms = terms => {
    this.setState({ terms });
  };

  setTerm = currentTerm => {
    this.setState({ currentTerm });
  };

  render() {
    const { loading, user, school, terms, currentTerm } = this.state;

    if (loading) return <Loader />;
    if (!user.id)
      return (
        <ThemeProvider theme={theme}>
          <SnackbarProvider>
            <CssBaseline />
            <Switch>
              <Route path="/onlinefees/:hash/:schoolID/:termID/:studentID/:school">
                <OnlineFeesSelect client={this.altCLient} />
              </Route>
              <Route path="/verifyonlinefees">
                <VerifyOnlineFees client={this.altCLient} />
              </Route>
              <Route path="/onlinefees/:hash">
                <OnlineFees client={this.altCLient} />
              </Route>

              <Route path="/">
                <SignIn />
              </Route>
            </Switch>
          </SnackbarProvider>
        </ThemeProvider>
      );
    return (
      <ApolloProvider client={this.client}>
        <UserContext.Provider value={{ user, setUser: this.setUser }}>
          <SchoolContext.Provider value={{ school, setSchool: this.setSchool }}>
            <TermsContext.Provider
              value={{
                terms,
                currentTerm,
                setTerms: this.setTerms,
                setTerm: this.setTerm,
              }}
            >
              <ThemeProvider theme={theme}>
                <SnackbarProvider>
                  <CssBaseline />
                  <Switch>
                    <Route path="/onlinefees/:hash/:schoolID/:termID/:studentID/:school">
                      <OnlineFeesSelect client={this.altCLient} />
                    </Route>
                    <Route path="/verifyonlinefees">
                      <VerifyOnlineFees client={this.altCLient} />
                    </Route>
                    <Route path="/onlinefees/:hash">
                      <OnlineFees client={this.altCLient} />
                    </Route>
                    <Route path="/" exact>
                      <App />
                    </Route>
                    <Route path="/login">
                      <Redirect to="/" />
                    </Route>
                    <Route path="/schools">
                      <Schools />
                    </Route>
                    {user.schoolID === '0' && (
                      <Route path="/*">
                        <Schools />
                      </Route>
                    )}
                    <Route path="/dashboard">
                      <Dashboard />
                    </Route>
                    <Route path="/users">
                      <Users />
                    </Route>
                    <Route path="/classes">
                      <Classes />
                    </Route>
                    <Route path="/students">
                      <Students />
                    </Route>
                    <Route path="/attendance">
                      <DailyAttendances />
                    </Route>
                    <Route path="/reports">
                      <Reports />
                    </Route>
                    <Route path="/fees">
                      <Fees />
                    </Route>
                    <Route path="/expenses">
                      <Expenses />
                    </Route>
                    <Route path="/settings">
                      <Settings />
                    </Route>
                    <Route path="/promotions">
                      <Promotions />
                    </Route>
                    <Route path="/teacher">
                      <Teacher />
                    </Route>
                    <Route path="/teacherLive">
                      <TeacherLive />
                    </Route>
                    <Route path="/studentLectures">
                      <StudentLectures />
                    </Route>
                    <Route path="/studentLive">
                      <StudentLive />
                    </Route>
                    <Route path="/buses">
                      <Buses />
                    </Route>
                    <Route path="/:source">
                      <FeesRedirect client={this.altCLient} />
                    </Route>
                  </Switch>
                </SnackbarProvider>
              </ThemeProvider>
            </TermsContext.Provider>
          </SchoolContext.Provider>
        </UserContext.Provider>
      </ApolloProvider>
    );
  }
}
ReactDOM.render(
  <Router>
    <Index />
  </Router>,
  document.querySelector('#root')
);
registerServiceWorker();
