import { useMutation, useQuery } from '@apollo/client';
import { Paper } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Field, Form, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import React from 'react';
import { SEND_WHATSAPP_MSG_MUTATION } from '../resolvers/Mutations';
import {
  GET_SECTIONS_ONLY_QUERY,
  GET_STUDENTS_ENABLED_QUERY,
} from '../resolvers/Queries';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
  },
  wrapper: {
    padding: 20,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  radio: {
    width: '100%',
    marginBottom: 10,
  },
  options: {
    width: '100%',
    marginBottom: 10,
  },
  break: {
    flexBasis: '100%',
    height: 0,
  },
  content: {
    padding: 40,
    marginTop: 20,
  },
  main: {
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: '100vh',
  },
  button: {
    marginTop: 30,
  },
  save: {
    marginTop: 10,
    marginBottom: 30,
  },
  select: {
    width: '100%',
    marginBottom: 10,
    marginTop: 10,
  },
  form: {
    width: '100%',
    minHeight: 18,
  },
}));
export default function WhatsappMsg(pr) {
  const { school } = pr;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [sections, setSections] = React.useState([]);
  const [students, setStudents] = React.useState([]);
  const [sendWhatsapp, sendWhatsappData] = useMutation(
    SEND_WHATSAPP_MSG_MUTATION
  );
  const sectionsData = useQuery(GET_SECTIONS_ONLY_QUERY, {
    variables: { id: school.id, currentTerm: school.termID },
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      setSections(data.sections);
    },
  });
  const studentsData = useQuery(GET_STUDENTS_ENABLED_QUERY, {
    variables: { termID: school.termID },
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      setStudents(data.students);
    },
  });

  return (
    <Box className={classes.container}>
      <Paper className={classes.wrapper}>
        <Typography component="h1" variant="h5">
          Whatsapp Messages
        </Typography>
        <div className={classes.content}>
          <Formik
            initialValues={{
              selection: '0',
              schoolID: school.id,
              termID: school.termID,
              sections: [],
              students: [],
              template: '',
            }}
            onSubmit={async (values, actions) => {
              try {
                const data = {
                  ...values,
                  sections: values.sections.map(s => s.id),
                  students: values.students.map(s => s.id),
                };
                delete data.selection;
                await sendWhatsapp({
                  variables: {
                    data,
                  },
                });
                enqueueSnackbar(`Whatsapp Messages Queued Successfully!`, {
                  variant: 'success',
                  anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                });
                if (sendWhatsappData.error) {
                  enqueueSnackbar(
                    `There was an error. Please check console for more details`,
                    {
                      variant: 'error',
                      anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                    }
                  );
                  console.log(sendWhatsappData.error);
                }
              } catch (e) {
                console.log(e);
                enqueueSnackbar(
                  `There was an error. Please check console for more details`,
                  {
                    variant: 'error',
                    anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                  }
                );
              }
              actions.resetForm();
            }}
          >
            {props => {
              const { values, handleChange, setFieldValue } = props;
              return (
                <>
                  <Form
                    style={{
                      width: '50%',
                      marginTop: 20,
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 10,
                    }}
                  >
                    <Field name="template">
                      {({ field }) => (
                        <FormControl
                          style={{
                            width: '100%',
                            marginBottom: 20,
                            marginTop: 20,
                          }}
                        >
                          <InputLabel id="demo-simple-select-label">
                            Select Whatsapp Template
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={values.template}
                            onChange={e =>
                              setFieldValue('template', e.target.value)
                            }
                          >
                            <MenuItem value="username_password_schools">
                              Username & Password Educast App
                            </MenuItem>
                            <MenuItem value="maneckji_login_details">
                              GPS App Login Details
                            </MenuItem>
                          </Select>
                        </FormControl>
                      )}
                    </Field>

                    <FormControl
                      component="fieldset"
                      style={{
                        width: '100%',
                        marginBottom: 20,
                        marginTop: 20,
                      }}
                    >
                      <FormLabel component="legend">
                        Select whom to send whatsapp messages
                      </FormLabel>
                      <RadioGroup
                        aria-label="selection"
                        name="selection"
                        value={values.selection}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value="0"
                          control={<Radio />}
                          label="Full School"
                          disabled={
                            sectionsData.loading || studentsData.loading
                          }
                        />
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label="Select Sections"
                          disabled={
                            sectionsData.loading || studentsData.loading
                          }
                        />
                        {values.selection === '1' && sections.length > 0 && (
                          <div>
                            <Field name="sections">
                              {({ field }) => (
                                <Autocomplete
                                  multiple
                                  value={values.sections}
                                  onChange={(event, newValue) => {
                                    setFieldValue('sections', newValue);
                                  }}
                                  id="tags-outlined"
                                  options={sections}
                                  getOptionLabel={option =>
                                    `${option.class.class} - ${option.section}`
                                  }
                                  defaultValue={[]}
                                  filterSelectedOptions
                                  renderInput={params => (
                                    <TextField
                                      {...params}
                                      variant="outlined"
                                      label="Select Sections"
                                      placeholder="Sections"
                                    />
                                  )}
                                />
                              )}
                            </Field>
                          </div>
                        )}
                        <FormControlLabel
                          value="2"
                          control={<Radio />}
                          label="Select Students"
                          disabled={
                            sectionsData.loading || studentsData.loading
                          }
                        />
                        {values.selection === '2' && students.length > 0 && (
                          <div>
                            <Field name="students">
                              {({ field }) => (
                                <Autocomplete
                                  multiple
                                  value={values.students}
                                  onChange={(event, newValue) => {
                                    setFieldValue('students', newValue);
                                  }}
                                  id="tags-outlined"
                                  options={students}
                                  getOptionLabel={option =>
                                    `${option.name} - ${option.class.class} - ${option.section.section}`
                                  }
                                  defaultValue={[]}
                                  filterSelectedOptions
                                  renderInput={params => (
                                    <TextField
                                      {...params}
                                      variant="outlined"
                                      label="Select Students"
                                      placeholder="Students"
                                    />
                                  )}
                                />
                              )}
                            </Field>
                          </div>
                        )}
                      </RadioGroup>
                    </FormControl>
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      className={classes.button}
                      disabled={sendWhatsappData.loading}
                      startIcon={<CloudUploadIcon />}
                    >
                      Send Messages
                    </Button>
                  </Form>
                </>
              );
            }}
          </Formik>
        </div>
      </Paper>
    </Box>
  );
}
