import { Button, makeStyles, Paper } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import DatePicker from './DatePicker';
import SelectField from './SelectField';
// import ReadXlsx from './ReadXlsx';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  radio: {
    width: '100%',
    marginBottom: 10,
  },
  options: {
    width: '100%',
    marginBottom: 10,
  },
  break: {
    flexBasis: '100%',
    height: 0,
  },
  wrapper: {
    width: '100%',
  },
  content: {
    padding: 10,
  },
  main: {
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: '100vh',
  },
  button: {
    height: 55,
  },
  form: {
    // maxWidth: '25%',
    display: 'flex',
    flexDirection: 'row',
  },
}));

export default function DateRangePicker(props) {
  const { school, years, submit } = props;
  const classes = useStyles();

  return (
    <Paper className={classes.wrapper}>
      <div className={classes.content}>
        <Formik
          initialValues={{
            currentTerm: school.termID,
            start: null,
            end: null,
          }}
          onSubmit={async values => {
            // values.start = values.start.utc().startOf('day');
            // values.end = values.end.utc().endOf('day');
            await submit(values.start, values.end, values.currentTerm);
          }}
          validationSchema={Yup.object().shape({
            currentTerm: Yup.string().required('Year Is Required'),
            start: Yup.date().required('Start Date is Required'),
            end: Yup.date().required('End Date is Required'),
          })}
        >
          {p => {
            const { values } = p;
            return (
              <Form className={classes.form}>
                <div style={{ flex: 0.25, padding: 10, marginTop: 10 }}>
                  <Field
                    name="start"
                    label="Start Date"
                    component={DatePicker}
                  />
                </div>
                <div style={{ flex: 0.25, padding: 10, marginTop: 10 }}>
                  <Field
                    name="end"
                    label="End Date"
                    minimum={values.start}
                    component={DatePicker}
                  />
                </div>
                <div style={{ flex: 0.25, padding: 10 }}>
                  <Field
                    name="currentTerm"
                    component={SelectField}
                    options={years}
                    label="Select A Year"
                    zIndex={5}
                  />
                </div>

                <div style={{ flex: 0.25, padding: 10, marginTop: 10 }}>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    fullWidth
                    className={classes.button}
                    startIcon={<GetAppIcon />}
                  >
                    Get Entries
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Paper>
  );
}
