import { useMutation, useQuery } from '@apollo/client';
import { Button, Paper, Typography } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import { useSnackbar } from 'notistack';
import React from 'react';
import { ReactSortable } from 'react-sortablejs';
import {
  DELETE_FEE_MUTATION,
  UPDATE_FEES_MUTATION,
} from '../resolvers/Mutations';
import { GET_FEES_QUERY } from '../resolvers/Queries';
import Loader from './Loader';
import PlainSelect from './PlainSelect';

import ConfirmDialog from './ConfirmDialog';
import UpdateFeeForm from './UpdateFeeForm';

const useStyles = makeStyles(() => ({
  container: {
    marginTop: 10,
    padding: 20,
  },
  main: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  wrapper: {
    width: '50%',
    padding: 10,
  },
  button: {
    height: 55,
    color: '#FFF',
    fontWeight: 'bold',
  },
  feeItem: {
    cursor: 'pointer',
    border: '1 solid grey',
    padding: 2,
  },
  ListItem: {
    backgroundColor: '#ECECF1',
  },
}));

export default function SortFees(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { school, feesCats } = props;
  const [fees, setFees] = React.useState([]);
  const [feeCat, setFeeCat] = React.useState('');
  const [confirmedFeeCat, setConfirmedFeeCat] = React.useState(false);
  const [updateFeeShow, setUpdateFeeShow] = React.useState(false);
  const [currentRow] = React.useState(false);
  const [confirmDialog, setConfirmDialog] = React.useState(false);
  const [deleteFeeMutation, deleteFeeData] = useMutation(DELETE_FEE_MUTATION);
  const feesData = useQuery(GET_FEES_QUERY, {
    variables: {
      feesStructureID: feeCat.value,
      termID: school.termID,
    },
    fetchPolicy: 'network-only',
    onCompleted: data => {
      setFees(data.fees);
    },
  });
  const [updateFees, updateFeesData] = useMutation(UPDATE_FEES_MUTATION);
  const deleteFee = async () => {
    try {
      await deleteFeeMutation({
        variables: {
          id: currentRow.id,
        },
      });
      await feesData.refetch();
      enqueueSnackbar(`${currentRow.name} has been successfully deleted!`, {
        variant: 'success',
        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
      });
    } catch (e) {
      enqueueSnackbar(`Action Failed. Heres the error: ${e}`, {
        variant: 'success',
        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
      });
    }
    setConfirmDialog(false);
  };
  const saveFeesSortOrder = async () => {
    const feesUpdatePromises = [];
    fees.map((f, i) => {
      feesUpdatePromises.push(
        updateFees({
          variables: {
            feeID: f.id,
            cols: {
              sr: i + 1,
            },
          },
        })
      );
      return f;
    });
    try {
      await Promise.all(feesUpdatePromises);
    } catch (e) {
      console.log(e);
    }

    setConfirmedFeeCat(false);
  };
  if (feesData.loading || updateFeesData.loading || deleteFeeData.loading)
    return <Loader />;
  return (
    <div>
      <Typography component="h1" variant="h6">
        Sort Fees Order
      </Typography>
      <div className={classes.main}>
        <div className={classes.wrapper}>
          <PlainSelect
            selectedVal={feeCat}
            onChange={setFeeCat}
            options={feesCats}
            placeholder="Select A Fee Category To See Fees Chart"
            isDisabled={!!confirmedFeeCat}
          />
        </div>
        <div className={classes.wrapper}>
          <Button
            variant="contained"
            color={confirmedFeeCat ? 'primary' : 'secondary'}
            fullWidth
            className={classes.button}
            onClick={() => setConfirmedFeeCat(!confirmedFeeCat)}
          >
            {confirmedFeeCat ? 'Reset Fee Category' : 'Confirm Fee Category'}
          </Button>
        </div>
      </div>
      {confirmedFeeCat && (
        <div>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            className={classes.button}
            onClick={() => saveFeesSortOrder()}
          >
            Save Sort Order
          </Button>
        </div>
      )}
      <div>
        {confirmedFeeCat && (
          <Paper className={classes.container}>
            <List dense />
            <ReactSortable list={fees} setList={setFees}>
              {fees.map((item, index) => (
                <div key={item.id} className={classes.feeItem}>
                  <ListItem className={classes.ListItem}>
                    <ListItemIcon>
                      <DragHandleIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={`Sr. No.: ${index + 1} - ${item.name}`}
                      secondary={`Amount : ${item.amount}  ${item.month ? `Due: ${item.month}` : 'Due: '
                        }`}
                    />
                    {/* <IconButton
                      edge="end"
                      aria-label="Edit Fee"
                      onClick={() => {
                        setCurrentRow(item);
                        setUpdateFeeShow(true);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      edge="end"
                      aria-label="Delete Fee"
                      onClick={() => {
                        setCurrentRow(item);
                        setConfirmDialog(true);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton> */}
                  </ListItem>
                </div>
              ))}
            </ReactSortable>
          </Paper>
        )}
      </div>
      <UpdateFeeForm
        open={updateFeeShow}
        handleClose={async () => {
          await feesData.refetch();
          // setFees(data.fees);
          setUpdateFeeShow(false);
        }}
        data={currentRow}
        termID={school.termID}
        feeCatID={feeCat.value}
        updateFees={updateFees}
      />
      <ConfirmDialog
        open={confirmDialog}
        handleConfirmClose={() => setConfirmDialog(false)}
        handleSubmit={deleteFee}
      />
    </div>
  );
}
