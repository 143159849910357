import { useQuery } from '@apollo/client';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import React from 'react';

import BusList from '../components/BusList';
import Layout from '../components/Layout';
import Loader from '../components/Loader';
import UserContext from '../components/UserContext';
import { GET_SCHOOL_QUERY } from '../resolvers/Queries';
import Authorize from './Authorize';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    width: '50%',
    flexDirection: 'column',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  radio: {
    width: '100%',
    marginBottom: 10,
  },
  options: {
    width: '100%',
    marginBottom: 10,
  },
  break: {
    flexBasis: '100%',
    height: 0,
  },
  wrapper: {
    width: '48%',
  },
  content: {
    padding: 10,
  },
  main: {
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: '100vh',
  },
  button: {
    marginTop: 30,
  },
  save: {
    marginTop: 10,
    marginBottom: 30,
  },
  select: {
    width: '100%',
    marginBottom: 10,
    marginTop: 10,
  },
  form: {
    width: '100%',
    minHeight: 18,
  },
  box: {
    width: '100%',
    marginBottom: 10,
    height: '100vh',
  },
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}
export default function Buses() {
  const classes = useStyles();
  const theme = useTheme();

  const { user } = React.useContext(UserContext);
  const [value, setValue] = React.useState(0);

  const schoolData = useQuery(GET_SCHOOL_QUERY, {
    variables: { id: user.schoolID },
  });
  const permissions = ['SUPERADMIN', 'ADMIN'];
  const handleChange = (event = '', newValue) => {
    setValue(newValue);
  };
  if (schoolData.loading) return <Loader />;
  if (!schoolData.data.schools_by_pk.buses)
    return (
      <Authorize pms={permissions}>
        <Layout>
          <div className={classes.box}>
            <h1
              style={{
                textAlign: 'center',
                marginTop: '20%',
                color: '#b4c9de',
              }}
            >
              This module is disabled. Please contact support to enable
            </h1>
          </div>
        </Layout>
      </Authorize>
    );
  return (
    <Authorize pms={permissions}>
      <Layout>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            // variant="fullWidth"
            centered
            aria-label="full width tabs example"
          >
            <Tab label="Buses" {...a11yProps(0)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0} dir={theme.direction}>
          <BusList schoolData={schoolData.data.schools_by_pk} />
        </TabPanel>
      </Layout>
    </Authorize>
  );
}
